import { PlayCircleFilled, PauseOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import UploadIcon from "../Icon/UploadIcon";
import { Upload } from "antd";
import createObjectURLWorker from "../../workers/createObjectURLWorker";
import WorkerBuilder from "../../workers/builder";
// import { readFileInChunks } from "../../hooks/files/files";
import { LanguageContext } from "../../context/Language";

const AudioPlayer = ({
  replaceMediaChangeHandler = null,
  dataFromFile = null,
  dataFromURI = null,
  showAsIconButton = false,
}) => {
  const audioRef = useRef();
  const { dictionary } = useContext(LanguageContext);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    const audioElement = audioRef.current;
    // Add event listeners to the audio element
    audioElement.addEventListener("play", play);
    audioElement.addEventListener("pause", pause);

    // Clean up event listeners when the component unmounts
    return () => {
      audioElement.removeEventListener("play", play);
      audioElement.removeEventListener("pause", pause);
    };
  }, []);

  useEffect(() => {
    if (dataFromFile && dataFromFile.originFileObj) {
      // Create a new worker cuz we terminate it when the component unmounts
      const AudioWorker = new WorkerBuilder(createObjectURLWorker);
      AudioWorker.onmessage = function (event) {
        const objectURL = event.data.objectURL;
        setAudioSource(objectURL, dataFromFile?.type);
      };

      AudioWorker.postMessage(dataFromFile.originFileObj);

      // Cleanup: terminate the worker when the component unmounts
      return () => {
        AudioWorker.terminate();
      };
    }
  }, [dataFromFile]);

  useEffect(() => {
    if (dataFromURI) {
      const { url, filename, format } = dataFromURI;
      const audio = url + "/" + filename;
      setAudioSource(audio, format === "wav" ? "audio/x-wav" : "audio/flac");
    }
  }, [dataFromURI]);

  const setAudioSource = (audioUrl, fileType = "audio/x-wav") => {
    const audioTag = audioRef.current;
    if (audioTag) {
      while (audioTag.firstChild) audioTag.removeChild(audioTag.firstChild);

      const source = document.createElement("source");
      source.setAttribute("type", fileType);
      source.setAttribute("src", audioUrl);
      source.onerror = () => console.log("source audio error");
      audioTag.append(source);
      audioTag.src = audioUrl;
      audioTag.type = fileType;
      audioTag.preload = "auto";
      audioTag.load();
      audioTag.volume = 0.5;
    }
  };

  const play = () => {
    try {
      audioRef.current.play();
      setIsPlaying(true);
    } catch (error) {
      setIsPlaying(false);
    }
  };

  const pause = () => {
    try {
      audioRef.current.pause();
      setIsPlaying(false);
    } catch (error) {
      setIsPlaying(true);
    }
  };

  if (showAsIconButton)
    return (
      <div>
        {isPlaying ? (
          <PauseOutlined className="icon-pause-track" onClick={() => pause()} />
        ) : (
          <PlayCircleFilled
            className="icon-play-track"
            onClick={() => play()}
          />
        )}
        <audio
          preload="auto"
          ref={audioRef}
          controls="nodownload"
          className="hidden"
          hidden
        >
          <source />
          Your browser does not support the <code>audio</code> element.
        </audio>
      </div>
    );

  return (
    <Row>
      <Col xs={24} sm={14} md={18} lg={19}>
        <h2>{dictionary.audio}</h2>
        <audio
          preload="auto"
          ref={audioRef}
          controls="nodownload"
          className="audio-preview"
        >
          <source src="" />
          Your browser does not support the <code>audio</code> element.
        </audio>
      </Col>
      {replaceMediaChangeHandler ? (
        <Col className="replace-media-col" xs={24} sm={9} md={6} lg={4}>
          <Upload
            customRequest={() => {}}
            name="avatar"
            accept="audio/x-wav, audio/wav, audio/x-flac, audio/flac"
            showUploadList={false}
            onChange={replaceMediaChangeHandler}
            listType="picture-card"
            className={"track-media-uploader ml-1"}
            maxCount={1}
          >
            <UploadIcon className="upload-icon" /> {dictionary.replaceMedia}
          </Upload>
        </Col>
      ) : null}
    </Row>
  );
};

export default AudioPlayer;
