import BaseClass from "../";

class ArtistCalls extends BaseClass {
  create = (body) => {
    this.setValues("/artist/create", body);
    return this.doPost();
  };

  update = (body) => {
    this.setValues("/artist/update", body);
    return this.doPatch();
  };

  delete = (artist) => {
    this.setValues("/artist/delete/" + artist);
    return this.doDelete();
  };

  updateImage = (body) => {
    this.setValues("/artist/update/image", body);
    return this.doPatch();
  };
}

export default new ArtistCalls();
