import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/App";
import { UserContext } from "../../context/User";
import { LanguageContext } from "../../context/Language";
import Search from "../../components/Form/Search";
import SearchIcon from "../../components/Icon/SearchIcon";
import ArtistList from "../../components/Catalog/ArtistList";
import "../../components/Catalog/catalog.css";

const Artist = () => {
  const { dictionary } = useContext(LanguageContext);
  const { labels } = useContext(AppContext);
  const { type, isAdmin } = useContext(UserContext);
  const [search, getSearch] = useState(null);
  const [allLabels, setLabels] = useState();
  const [labelArtist, setLabelArtist] = useState([]);
  const [loading, setLoading] = useState(false);

  const getLabels = async () => {
    setLoading(true);
    try {
      const labelsArray = labels || [];
      if (type === "artist") {
        setLabelArtist(labelsArray[0] ?? []);
      } else {
        setLabels(labelsArray);
      }
      setLoading(false);
    } catch (e) {
      setLabels([]);
      setLabelArtist([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    getLabels();
  }, [labels]);

  const onSearch = (value) => {
    getSearch(value.target.value);
  };

  return (
    <React.Fragment>
      <div className={isAdmin}>
        {type !== "admin" ? (
          <div className="title-catalog">
            <div className="filters">
              <Search
                name="searcher"
                value={search}
                onSearch={onSearch}
                className="searcher back"
                enterButton={<SearchIcon />}
                placeholder={dictionary.search}
              />
            </div>
          </div>
        ) : null}
        <ArtistList
          labels={allLabels}
          labelArtist={labelArtist}
          searchValues={search}
          filter={1}
          loadList={loading}
        />
      </div>
    </React.Fragment>
  );
};

export default Artist;
