import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../../context/User";
import { Row, Col, Upload, message } from "antd";
import { LanguageContext } from "../../../context/Language";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import "../set.css";
import validator from "validator";
import Loader from "../../Loader";
import Input from "../../Form/Input";
import Phone from "../../Form/Phone";
import Select from "../../Form/Select";
import Button from "../../Buttons/GeneralButton";
import Country from "../../../classes/country/country.jsx";
import User from "../../../classes/user/user.jsx";

const Profile = () => {
  const { dictionary, locale } = useContext(LanguageContext);
  const { profileData, avatar, type, checkUser, parentId, parentLabel } = useContext(UserContext);
  const [imageUrl, setImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [dataCountries, setData] = useState([]);
  const [phoneCountries, setPhoneCountries] = useState([]);
  const [profile, setProfile] = useState({
    name: "",
    last: "",
    companyName: "",
    address: "",
    city: "",
    state: "",
    website: "",
    zipCode: "",
    country: 0,
    ladaCountry: "US",
    ladaCode: "",
    phone: "",
    image: "",
  });
  const [errors, setError] = useState({
    name: "",
    last: "",
    companyName: false,
    address: false,
    city: false,
    state: false,
    zipCode: false,
    country: false,
    phone: false,
  });

  useEffect(() => {
    if (phoneCountries.length === 0) getCountries();
  });

  useEffect(() => {
    if (profileData) {
      setProfile({
        name: profileData.name || "",
        last: profileData.surname || "",
        companyName: profileData.company_name || "",
        address: profileData.address.address || "",
        city: profileData.address.city || "",
        state: profileData.address.state || "",
        website: profileData.website || "",
        zipCode: profileData.address.zip || "",
        country: profileData.address.country || 0,
        ladaCountry: profileData.phone.country || "US",
        ladaCode: profileData.phone.code || 1,
        phone: profileData.phone.number || "",
        image: avatar || "",
      });
    }
    if (avatar) {
      setImage(avatar);
    }
  }, [profileData, avatar]);

  const getCountries = () => {
    Country.countries().then((countriesArr) => {
      try {
        const countries = [...countriesArr.body.countries];
        setData([...countries]);
        const phoneCountriesCopy = countries.map((el) => {
          let country = {};
          const phoneCode = el._id + " +" + el.phone_code;
          country._id = el._id;
          country.label = phoneCode;
          country.name = phoneCode;
          country.phone_code = el.phone_code;
          country.region = el.region;
          return country;
        });
        setPhoneCountries([...phoneCountriesCopy]);
      } catch (e) {
        setPhoneCountries([]);
      }
    });
  };

  const ladaChangeHandler = (e, name) => {
    setProfile({
      ...profile,
      ladaCountry: e,
      ladaCode: name.code,
    });
  };

  const changeHandler = (e) => {
    setProfile({
      ...profile,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelect = (e, name) => {
    setProfile({
      ...profile,
      [name]: e,
    });
  };

  const validate = async (inputName = "") => {
    let error = false;
    let baseError = {
      name: false,
      last: false,
      companyName: false,
      address: false,
      city: false,
      state: false,
      zipCode: false,
      country: false,
      phone: false,
    };
    setError(baseError);
    const location = locale.includes("es") ? "es-ES" : locale;

    if (inputName === "" || inputName === "name")
      if (validator.isLength(profile.name, 0, 0)) {
        baseError.name = dictionary.emptyField;
        error = true;
      } else if (
        !validator.isAlphanumeric(profile.name, [location], { ignore: ".-s'&" })
      ) {
        baseError.name = dictionary.onlyLettersAndChars;
        error = true;
      }

    if (inputName === "" || inputName === "last")
      if (validator.isLength(profile.last, 0, 0)) {
        baseError.last = dictionary.emptyField;
        error = true;
      } else if (
        !validator.isAlphanumeric(profile.last, [location], { ignore: ".-s'&" })
      ) {
        baseError.last = dictionary.onlyLettersAndChars;
        error = true;
      }

    if (inputName === "" || inputName === "companyName")
      if (validator.isLength(profile.companyName, 0, 0)) {
        baseError.companyName = dictionary.emptyField;
        error = true;
      } else if (
        !validator.isAlphanumeric(profile.companyName, [location], {
          ignore: ".-s'&",
        })
      ) {
        baseError.companyName = dictionary.onlyLettersAndChars;
        error = true;
      }

    if (inputName === "" || inputName === "address")
      if (validator.isLength(profile.address, 0, 0)) {
        baseError.address = dictionary.emptyField;
        error = true;
      } else if (
        !validator.isAlphanumeric(profile.address, ["es-ES"], {
          ignore: ".-s'&/",
        })
      ) {
        baseError.address = dictionary.onlyLettersAndChars;
        error = true;
      }

    if (inputName === "" || inputName === "city")
      if (validator.isLength(profile.city, 0, 0)) {
        baseError.city = dictionary.emptyField;
        error = true;
      } else if (
        !validator.isAlpha(profile.city, ["es-ES"], { ignore: "-s'" })
      ) {
        baseError.city = dictionary.onlyLetters;
        error = true;
      }

    if (inputName === "" || inputName === "zipCode")
      if (profile.zipCode.length < 3) {
        baseError.zipCode = dictionary.zipErr;
        error = true;
      }

    if (inputName === "" || inputName === "state")
      if (validator.isLength(profile.state, 0, 0)) {
        baseError.state = dictionary.emptyField;
        error = true;
      } else if (
        !validator.isAlpha(profile.state, ["es-ES"], { ignore: "-s'" })
      ) {
        baseError.state = dictionary.onlyLetters;
        error = true;
      }

    if (inputName === "" || inputName === "country")
      if (!validator.isAlpha(profile.country.toString())) {
        baseError.country = dictionary.selectErr;
        error = true;
      }

    if (inputName === "" || inputName === "website")
      if (profile.website.length > 0) {
        if (
          !validator.isURL(profile.website, { protocols: ["http", "https"] })
        ) {
          baseError.website = dictionary.websiteErr;
          error = true;
        } else {
          if (profile.website[profile.website.length - 1] === "/")
            profile.website = profile.website.slice(0, -1);
        }
      }

    if (inputName === "" || inputName === "phone")
      if (validator.isLength(profile.phone, 0, 0)) {
        baseError.phone = dictionary.emptyField;
        error = true;
      } else if (
        profile.phone.length < 8 ||
        !validator.isMobilePhone(profile.phone, "any")
      ) {
        baseError.phone = dictionary.phoneErr;
        error = true;
      }

    if (error) {
      setError(baseError);
    }

    return !error;
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
          color: "#8c8c8c",
        }}
      >
        {dictionary.upload}
      </div>
    </div>
  );

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const handleChange = ({ file }) => {
    setImageFile(file);
    if (file.status === "uploading") {
      setLoading(true);
      // Get this url from response in real world.
      getBase64(file.originFileObj, (imageUrl) => setImage(imageUrl));
    }
  };

  const success = () => {
    message.success({
      content: dictionary.profileSuccess,
      style: {
        marginTop: "50px",
      },
    });
  };

  const errorMsg = () => {
    message.error({
      content: dictionary.profileError,
      style: {
        marginTop: "50px",
      },
    });
  };

  const save = async () => {
    if (await validate()) {
      setLoader(true);
      if (imageFile && imageFile.originFileObj) {
        const imageArtist = await User.uploadProfileAvatar(
          imageFile.originFileObj
        );
        if (imageArtist) {
          profile.image = imageArtist;
        }
      }
      const updated = await User.updateProfile(profile);
      if (updated.status === 200) {
        setLoader(false);
        success();
        checkUser();
      } else {
        setLoader(false);
        errorMsg();
      }
    }
  };

  return (
    <div className="content-set">
      <div className="forms">
        <Row className="gutter-row">
          <Col xs={24} sm={24} md={12} lg={12} className="col1">
            <Input
              name="name"
              required={true}
              value={profile.name}
              error={errors.name}
              onChange={changeHandler}
              label={dictionary.firstName}
              className={errors.name ? "inputErr" : ""}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} className="col2">
            <Input
              name="last"
              required={true}
              value={profile.last}
              error={errors.last}
              onChange={changeHandler}
              label={dictionary.lastName}
              className={errors.last ? "inputErr" : ""}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} className="col1">
            {!parentId ? (
                <Input
                name="companyName"
                required={true}
                value={profile.companyName}
                onChange={changeHandler}
                error={errors.companyName}
                label={
                  type === "artist" 
                    ? dictionary.artistLabelName
                    : dictionary.companyName
                }
                className={errors.companyName ? "inputErr" : ""}
              />
            ) 
            :(
              <Input
                name="labelParent"
                value={parentLabel[0].name}
                onChange={changeHandler}
                disabled={true}
                label={dictionary.belongsToCompany}
              />
              )}
            <Input
              name="address"
              required={true}
              value={profile.address}
              onChange={changeHandler}
              error={errors.address}
              label={dictionary.address}
              className={errors.address ? "inputErr" : ""}
            />
            <Input
              name="city"
              required={true}
              value={profile.city}
              error={errors.city}
              label={dictionary.city}
              onChange={changeHandler}
              className={errors.city ? "inputErr" : ""}
            />
            <Input
              name="state"
              required={true}
              value={profile.state}
              error={errors.state}
              onChange={changeHandler}
              label={dictionary.state}
              className={errors.state ? "inputErr" : ""}
            />
            <Input
              name="website"
              required={false}
              error={errors.website}
              value={profile.website}
              onChange={changeHandler}
              label={dictionary.website}
              className={errors.website ? "inputErr" : ""}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} className="col2">
            <label className="allWidth">{dictionary.profilePicture}</label>
            <React.Fragment>
              <Upload
                name="avatar"
                accept={"image/*"}
                showUploadList={false}
                listType="picture-card"
                className="avatar-uploader avatar-profile"
                onChange={handleChange}
              >
                {imageUrl ? (
                  <img
                    alt="avatar"
                    src={imageUrl ? imageUrl : null}
                    className="avatar-profile"
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
            </React.Fragment>
            <Input
              name="zipCode"
              required={true}
              maxLength={10}
              value={profile.zipCode}
              onChange={changeHandler}
              error={errors.zipCode}
              label={dictionary.zip}
              className={errors.zipCode ? "inputErr" : ""}
            />
            <Select
              name="country"
              required={true}
              showSearch={true}
              value={profile.country}
              options={dataCountries}
              placeholder={dictionary.select}
              label={dictionary.country}
              onChange={(e) => handleSelect(e, "country")}
              className={
                (errors.country ? "countErr " : "") + " selector-general"
              }
            />
            <Phone
              name="phone"
              required={true}
              maxLength={11}
              value={profile.phone}
              onChange={changeHandler}
              error={errors.phone}
              lada={profile.ladaCountry}
              onChangeSelect={ladaChangeHandler}
              phoneCountries={phoneCountries}
              label={dictionary.phoneNumber}
              className={errors.phone ? "inputErr" : ""}
            />
          </Col>
        </Row>
      </div>
      <div className="reg-btn">
        <Button size="md" onClick={save} text={dictionary.save} />
      </div>
      {loader ? <Loader /> : null}
    </div>
  );
};

export default Profile;
