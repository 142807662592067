import React, { useContext, useEffect, useState } from "react";
import { Input, notification } from "antd";
import { LanguageContext } from "../../../context/Language";
import Modal from "../../Modal";
import Button from "../../Buttons/GeneralButton";
import AdminCalls from "../../../classes/admin/admin";

const ScheduleDeliveryActionModal = ({
  onConfirm,
  onCancel,
  isModalVisible,
  dspList = {},
  movedDsp = [],
  direction = "right",
}) => {

  const { dictionary } = useContext(LanguageContext);
  const [dataToSend, setDataToSend] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const isUpsert = direction === "right";

  useEffect(() => {
    if (movedDsp.length !== 0 && Object.keys(dspList).length !== 0) {
      const newDataToSend = {};
      movedDsp.forEach((dspKey) => {
        newDataToSend[dspKey] = dspList[dspKey]?.name || "";
      });
      setDataToSend(newDataToSend);
    }
  }, [dspList, movedDsp]);

  const sendHandler = () => {
    setIsLoading(true);
    const data = [];
    const keys = Object.keys(dataToSend);
    keys.forEach((key) => {
      data.push({
        audiosalad_id: key,
        name: dataToSend[key],
      });
    });
    AdminCalls.audioSaladDSPUpsert(data, direction)
      .then((res) => {
        if (res.status === 200) {
          notification.success({
            message: dictionary.success,
            duration: 5,
            placement: "bottomRight",
          });
        }
        onConfirm();
      })
      .catch(() => {
        notification.warning({
          message: dictionary.stripeError,
          duration: 5,
          placement: "bottomRight",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Modal
      idModal="release-reject-modal"
      handleCancel={() => (!isLoading ? onCancel() : null)}
      isModalVisible={isModalVisible}
      title={
        <div className="modal-title">
          {isUpsert ? dictionary.confirmChanges : dictionary.disable + " DSP's"}
        </div>
      }
      body={
        <div id="release-reject-modal-content">
          {movedDsp.map((dspKey) => {
            return isUpsert ? (
              <div className="mt-2" key={dspKey}>
                <label>AudioSalad ID: {dspKey}</label>
                <Input
                  rows={4}
                  placeholder={dspList[dspKey]?.name}
                  value={dataToSend[dspKey]}
                  onChange={(e) => {
                    setDataToSend({ ...dataToSend, [dspKey]: e.target.value });
                  }}
                />
              </div>
            ) : (
              <div className="mt-2" key={dspKey}>
                AudioSalad ID: {dspKey + " -> " + dataToSend[dspKey]}
              </div>
            );
          })}
        </div>
      }
      footer={
        <div id="release-reject-modal-actions" className="modalAdd-btns">
          <Button
            key="cancel"
            className="back-btn"
            text={dictionary.cancel}
            onClick={onCancel}
          />
          <Button
            size="ms"
            className="reject"
            onClick={() => sendHandler()}
            text={dictionary.send}
            disabled={isLoading}
          />
        </div>
      }
    />
  );
};

export default ScheduleDeliveryActionModal;
