import {
  Card,
  Col,
  Row,
  Input,
  Button,
  Select,
  Typography,
} from "antd";
import Phone from "../../../Form/Phone";
import React, { useContext, useState } from "react";
import MarkIcon from "../../../Icon/MarkIcon";
import { LanguageContext } from "../../../../context/Language";
import { AppContext } from "../../../../context/App";

const Profile = (props) => {

  const [enableEdit, setEnableEdit] = useState({ first: false, second: false });
  const { dictionary } = useContext(LanguageContext);
  const { ladaOptions, countriesOptions } = useContext(AppContext);

  const {
    dispatch,
    dataToUpdate,
    isEditMode,
  } = props;

  // key is the name of the property in enableEdit
  const toggleEditMode = (key = 'first') => {
    if (Object.prototype.hasOwnProperty.call(enableEdit, key)) {
      setEnableEdit((prevState) => ({
        ...prevState,
        [key]: !enableEdit[key],
      }));
    } else
      console.warn(
        `Key '${key}' not found in toggleEditMode at Profile.jsx`
      );
  };

  return (
    <div className="general_information user-detls">
      <Row>
        <Col>
          <Typography.Title level={3}>
            {dictionary.userProfile}
          </Typography.Title>
        </Col>
        <Col>
        </Col>
      </Row>
      <Row justify="space-around" className="user-div">
        <Col xs={24} lg={24} className="info-one">
          <Card loading={props.isLoading}>
            {isEditMode ? (
                <Button
                  className={"edit_button usr" + (enableEdit.first ? " active" : "")}
                  shape="circle"
                  icon={<MarkIcon />}
                  onClick={() => toggleEditMode("first")}
                />
            ) : null}
            <Row justify="space-between"  className="mt-2 mr-4 content-user">
              <Col className="label" xs={24}md={24} lg={12}>
                <label>{dictionary.name}</label>
                <Input
                  defaultValue={dataToUpdate?.name ?? props.profile?.name}
                  onChange={(e) => {
                    dispatch({
                      type: "simpleChange",
                      payload: {
                        name: "name",
                        original: props.profile?.name,
                        value: e.target.value,
                      },
                    });
                  }}
                  placeholder={dictionary.name}
                  disabled={!enableEdit.first}
                />
              </Col>
              <Col className="label"  xs={24}md={24} lg={12}>
                <label>{dictionary.lastName}</label>
                <Input
                  defaultValue={dataToUpdate?.surname ?? props.profile?.surname}
                  onChange={(e) => {
                    dispatch({
                      type: "simpleChange",
                      payload: {
                        name: "surname",
                        original: props.profile?.surname,
                        value: e.target.value,
                      },
                    });
                  }}
                  disabled={!enableEdit.first}
                />
              </Col>
              <Col className="label" xs={24}md={24} lg={12}>
                <label>{dictionary.companyName}</label>
                <Input
                  defaultValue={dataToUpdate?.company_name ?? props.profile?.company_name}
                  onChange={(e) => {
                    dispatch({
                      type: "simpleChange",
                      payload: {
                        name: "company_name",
                        original: props.profile?.company_name,
                        value: e.target.value,
                      },
                    });
                  }}
                  placeholder={dictionary.companyName}
                  disabled={!enableEdit.first}
                />
              </Col>
              <Col className="label" xs={24}md={24} lg={12}>
                <Phone
                  name="phone"
                  required={true}
                  maxLength={11}
                  className="user-phone"
                  classNameLada="user-lada"
                  value={dataToUpdate?.number || props.profile?.phone?.number}
                  lada={dataToUpdate?.ladaCountry || props.profile?.phone?.country}
                  disabled={!enableEdit.first}
                  phoneCountries={ladaOptions}
                  label={dictionary.phoneNumber}
                  onChange={(e) => {
                    dispatch({
                      type: "simpleChange",
                      payload: {
                        name: "number",
                        original: props.profile?.phone?.number,
                        value: e.target.value,
                      },
                    });
                  }}
                  onChangeSelect={(e, name) => {
                    dispatch({
                      type: "ladaphoneChange",
                      payload: {
                        name: "code",
                        original: props.profile?.phone?.code,
                        value: name,
                      },
                    });
                  }}
                />
              </Col>
              <Col className="label" xs={24}md={24} lg={12}>
                <label>{dictionary.address}</label>
                <Input
                  defaultValue={dataToUpdate.address || props.profile?.address?.address}
                  onChange={(e) => {
                    dispatch({
                      type: "simpleChange",
                      payload: {
                        name: "address",
                        original: props.profile?.address?.address,
                        value: e.target.value,
                      },
                    });
                  }}
                  disabled={!enableEdit.first}
                />
              </Col>
              <Col className="label" xs={24}md={24} lg={12}>
                <label>{dictionary.city}</label>
                <Input
                  defaultValue={dataToUpdate?.city || props.profile?.address?.city}
                  onChange={(e) => {
                    dispatch({
                      type: "simpleChange",
                      payload: {
                        name: "city",
                        original: props.profile?.address?.city,
                        value: e.target.value,
                      },
                    });
                  }}
                  disabled={!enableEdit.first}
                />
              </Col>
              <Col className="label"  xs={24}md={24} lg={12}>
                <label>{dictionary.state}</label>
                <Input
                  defaultValue={dataToUpdate?.state || props.profile?.address?.state}
                  onChange={(e) => {
                    dispatch({
                      type: "simpleChange",
                      payload: {
                        name: "state",
                        original: props.profile?.address?.state,
                        value: e.target.value,
                      },
                    });
                  }}
                  disabled={!enableEdit.first}
                />
              </Col>
              <Col className="label" xs={24}md={24} lg={12}>
                <label>{dictionary.zip}</label>
                <Input
                  defaultValue={dataToUpdate?.zip || props.profile?.address?.zip}
                  onChange={(e) => {
                    dispatch({
                      type: "simpleChange",
                      payload: {
                        name: "zip",
                        original: props.profile?.address?.zip,
                        value: e.target.value,
                      },
                    });
                  }}
                  disabled={!enableEdit.first}
                />
              </Col>
              <Col className="label"  xs={24}md={24} lg={12}>
                <label>{dictionary.country}</label>
                <Select
                  defaultValue={dataToUpdate?.country || props.profile?.address?.country}
                  options={countriesOptions}
                  onChange={(e) => {
                    dispatch({
                      type: "simpleChange",
                      payload: {
                        name: "country",
                        original: props.profile?.address?.country,
                        value: e,
                      },
                    });
                  }}
                  disabled={!enableEdit.first}
                />
              </Col>
              <Col className="label"  xs={24}md={24} lg={12}>
                <label>{dictionary.website}</label>
                <Input
                  defaultValue={dataToUpdate?.website ?? props.profile?.website}
                  onChange={(e) => {
                    dispatch({
                      type: "simpleChange",
                      payload: {
                        name: "website",
                        original: props.profile?.website,
                        value: e.target.value,
                      },
                    });
                  }}
                  disabled={!enableEdit.first}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Profile;
