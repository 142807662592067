import React, { useContext, useEffect, useState } from "react";
import { ClockCircleOutlined, CalendarOutlined } from "@ant-design/icons";
import { LanguageContext } from "../../../../context/Language";
import { AppContext } from "../../../../context/App";
import { Row, Col, Select, Button } from "antd";
import MarkIcon from "../../../../components/Icon/MarkIcon";
import Date from "../../../../components/Form/Date";
import "./delivery.css";

const Delivery = ({
  dataToUpdate,
  dispatch,
  tracks = [],
  priceTier = {},
  isEditMode,
}) => {
  const { dictionary } = useContext(LanguageContext);
  const { trackTiersOptions, albumTiersOptions } = useContext(AppContext);

  const [releaseTierName, setReleaseTierName] = useState(null);
  const [trackTierName, setTrackTierName] = useState(null);
  const [enableEdit, setEnableEdit] = useState({
    first: isEditMode,
    second: isEditMode,
  });

  useEffect(() => {
    if (tracks.length) {
      setTrackTierName(tracks[0]?.price_tier[0]?.name || null);
    }
  }, [tracks]);

  useEffect(() => {
    if (priceTier.length) {
      setReleaseTierName(priceTier[0].name || null);
    }
  }, [priceTier]);

  // key is the name of the property in enableEdit
  const toggleEditMode = (key) => {
    if (Object.prototype.hasOwnProperty.call(enableEdit, key)) {
      setEnableEdit((prevState) => ({
        ...prevState,
        [key]: !enableEdit[key],
      }));
    } else
      console.warn(`Key '${key}' not found in toggleEditMode at Delivery.jsx`);
  };

  return (
    <div className="rights-box">
      <Row>
        <Col sm={24} md={24} lg={12}>
          <div className="title delivery">
            <h1>{dictionary.deliveryInstructions}</h1>
            {isEditMode ? (
              <Button
                className={"edit_button" + (enableEdit.first ? " active" : "")}
                shape="circle"
                icon={<MarkIcon />}
                onClick={() => toggleEditMode("first")}
              />
            ) : null}
          </div>
          <div className="deliv-desc">
            {dictionary.deliveryInstructionsDesc}
          </div>
          <div className="rights-container delivery">
            <div className="right-column">
              <sub>{dictionary.dspRelDate}</sub>
              <Date
                value={""}
                onChange={""}
                className="date-delivery"
                disabled={!enableEdit.first}
                suffixIcon={<CalendarOutlined className="orng" />}
                placeholder={dictionary.selectDate}
              />
              <sub>{dictionary.assetLevelDate}</sub>
              <div className="asset-level-date">{dictionary.notSupported}</div>
            </div>
            <div className="right-column">
              <sub>{dictionary.releaseTime}</sub>
              <Date
                value={""}
                onChange={""}
                picker="time"
                disabled={!enableEdit.first}
                className="date-delivery"
                suffixText="UTC"
                suffixIcon={<ClockCircleOutlined className="orng" />}
                placeholder="--:--"
              />
              <sub>{dictionary.assetLevelTerritory}</sub>
              <div className="asset-level-date">{dictionary.notSupported}</div>
            </div>
          </div>
        </Col>
        <Col sm={24} md={24} lg={12}>
          <div className="title delivery">
            <h1>{dictionary.pricing}</h1>
            {isEditMode ? (
              <Button
                className={"edit_button" + (enableEdit.second ? " active" : "")}
                shape="circle"
                icon={<MarkIcon />}
                onClick={() => toggleEditMode("second")}
              />
            ) : null}
          </div>
          <div className="rights-container delivery">
            <div className="right-column">
              <sub>{dictionary.releasePriceTiers}</sub>
              <Select
                className="selector-general tier"
                defaultValue={dataToUpdate?.price_tier || releaseTierName}
                value={dataToUpdate?.price_tier || releaseTierName}
                options={albumTiersOptions}
                placeholder={dictionary.select}
                onChange={(e) => {
                  dispatch({
                    type: "stringChange",
                    payload: {
                      name: "price_tier",
                      original: releaseTierName,
                      value: e,
                    },
                  });
                }}
                disabled={!enableEdit.second}
              />
            </div>
            <div className="right-column">
              <sub>{dictionary.trackPriceTiers}</sub>
              <Select
                className="selector-general tier"
                defaultValue={dataToUpdate?.track_price_tier || trackTierName}
                value={dataToUpdate?.track_price_tier || trackTierName}
                options={trackTiersOptions}
                placeholder={dictionary.select}
                onChange={(e) => {
                  dispatch({
                    type: "stringChange",
                    payload: {
                      name: "track_price_tier",
                      original: trackTierName,
                      value: e,
                    },
                  });
                }}
                disabled={!enableEdit.second}
              />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Delivery;
