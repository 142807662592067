import AdminCalls from "../calls/admin/admin.jsx";
import uploadS3Calls from "../calls/amazon/uploadS3.jsx";
import { v4 as uuidv4 } from "uuid";

class Admin {
  /**
   * @param {string} labelId
   * @returns {null|object}
   */
  async getArtistsByLabelId(labelId) {
    const res = await AdminCalls.artistsByLabelId(labelId);
    if (res.status === 200) {
      return res.body;
    }
    return { error: true, status: res.status };
  }

  /**
   * Add or replace a UPC to a release
   * @param {string} releaseId
   * @returns {null|object} if succeed contain the release id
   */
  async releaseUPC(releaseId) {
    const res = await AdminCalls.releaseUPC(releaseId);
    if (res.status === 200) {
      return res.body;
    }
    return { error: true, message: res.body.error, status: res.status };
  }

  /**
   * This endpoint is more flexible than the user endpoint.
   * It will allow you to update only certain fields
   * @param {string} releaseId
   * @param {object} releaseBodyData
   * @returns {null|object} if succeed contain the release id
   */
  async releaseUpdate(releaseId, releaseBodyData) {
    const res = await AdminCalls.releaseUpdate(releaseId, releaseBodyData);
    if (res.status === 200) {
      return res.body;
    }
    return { error: true, status: res.status };
  }

  /**
   * Add or replace a UPC to a release
   * @param {string} releaseId
   * @returns {null|object} if succeed contain the release id
   */
  async trackISRC(trackId) {
    const res = await AdminCalls.trackISRC(trackId);
    if (res.status === 200) {
      return res.body;
    }
    return { error: true, message: res.body.error, status: res.status };
  }

  /**
   * This endpoint is more lax than the user endpoint.
   * It will allow you to create a track without the asset
   * @param {object} trackBodyData
   * @returns {null|object} if succeed contain the track id
   */
  async trackCreate(trackBodyData) {
    const res = await AdminCalls.trackCreate(trackBodyData);
    if (res.status === 200) {
      return res.body;
    }
    return { error: true, status: res.status };
  }

  /**
   * This endpoint its only a separate endpoint to update a track
   * should work the same as the user endpoint
   * @param {string} trackId
   * @param {object} trackBodyData
   * @returns {null|object} if succeed contain the track id
   */
  async trackUpdate(trackId, trackBodyData) {
    const res = await AdminCalls.trackUpdate(trackId, trackBodyData);
    if (res.status === 200) {
      return res.body;
    }
    return { error: true, status: res.status };
  }

  /**
   * Delete a track frpm the db and the s3
   * @param {string} trackId
   * @returns {null|object} if succeed contain the track id
   */
  async trackDelete(trackId) {
    const res = await AdminCalls.trackDelete(trackId);
    if (res.status === 200) {
      return res.body;
    }
    return { error: true, status: res.status };
  }

  /**
   * Request a membership change for a user
   * @param {string} userId
   * @returns {null|object} if succeed contain the track id
   */
  async switchUserMembership(userId) {
    const res = await AdminCalls.switchUserMembership(userId);
    if (res.status === 200) {
      return res.body;
    }
    return { error: true, status: res.status };
  }

  async updateStatus(release) {
    return await AdminCalls.updateStatus(release);
  }

  async setReleaseInDraft(release, note = null) {
    return await AdminCalls.setReleaseInDraft(release, note);
  }

  async changePriority(release, priority) {
    return await AdminCalls.changePriority(release, priority);
  }

  async audioSaladDSPList() {
    const response = await AdminCalls.audioSaladDSPList();
    if (response.status === 200) {
      return response.body;
    }
    return { error: true, status: response.status };
  }

  async audioSaladDSPUpsert(dsps, direction) {
    return await AdminCalls.audioSaladDSPUpsert({
      dsps,
      action: direction === "right" ? "upsert" : "remove",
    });
  }

  async audioSaladApiStatus() {
    return await AdminCalls.audioSaladApiStatus();
  }

  async refreshAudioSaladApi(token) {
    return await AdminCalls.refreshAudioSaladApi(token);
  }

  async audioSaladScheduleDelivery(body) {
    return await AdminCalls.audioSaladScheduleDelivery(body);
  }

  async getAllUsers() {
    return await AdminCalls.getAllUsers();
  }

  async uploadFile(data) {
    const { file, releaseId, trackId = "", onUploadProgress, type } = data;
    const re = /(?:\.([^.]+))?$/;
    const ext = re.exec(file.name)[1];
    const fileName = (type === "cover" ? "cover" : uuidv4()) + "." + ext;
    // folder/filename
    const uploadS3 = new uploadS3Calls(releaseId, fileName);
    const url = await uploadS3.createSignedUrl(file);

    const onProgress = onUploadProgress
      ? (progress) => onUploadProgress(progress, { trackId, releaseId, type })
      : null;

    return uploadS3.uploadFile(url, file, onProgress);
  }

  async userStatus(userid, status) {
    return await AdminCalls.userStatus(userid, status);
  }

  async userTempPwd(user) {
    return await AdminCalls.userTempPwd(user);
  }

  async contracts(where = {}, order = {}, limit = 10, offset = 0) {
    return await AdminCalls.contracts(limit, offset, where, order);
  }

  async addContract(curveId, labelId) {
    return await AdminCalls.addContract(curveId, labelId);
  }

  async updateContract(contractId, body) {
    return await AdminCalls.updateContract(contractId, body);
  }

  async enableContract(contractId) {
    return await AdminCalls.enableContract(contractId);
  }

  async disableContract(contractId) {
    return await AdminCalls.disableContract(contractId);
  }

  async updatePayee(labelId, payee) {
    return await AdminCalls.updatePayee(labelId, payee);
  }
}

export default new Admin();
