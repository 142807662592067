import React, { useContext, useEffect, useState, useReducer } from "react";
import { Alert, Collapse } from "antd";
const { Panel } = Collapse;
import { LanguageContext } from "../../../../../context/Language";
import Modal from "../../../../Modal";
import Button from "../../../../Buttons/GeneralButton";
import TrackGeneralInformation from "./TrackGeneralInformation";
import TrackCopyright from "./TrackCopyright";
import TrackContributors from "./TrackContributors";
import ISRCSetter from "./ISRCSetter";
import AudioPlayer from "../../../../AudioPlayer";
import { releaseDetailReducer } from "../../../../../util";
import useTrackValidator from "../../../../../hooks/validators/useTrackValidator";

const TrackCrudModal = ({
  handleSave,
  handleCancel,
  modalVisible,
  trackData,
  tracks,
  setTracks,
  isEditMode,
  triggerUpdateAudioUpload,
  labelId,
  numberTracks,
  priceTier,
  releaseData,
  setShowAddUpcIsrcModal,
}) => {
  const [dataToUpdate, dataToUpdateDispatcher] = useReducer(
    releaseDetailReducer,
    {}
  );
  const { dictionary } = useContext(LanguageContext);

  const [audiofile, setAudioFile] = useState(null);
  const [audioUrl, setAudioUrl] = useState(null);
  const { trackIsValid, validateTrackErrorMessages, validateTrack } =
    useTrackValidator();

  useEffect(() => {
    // The modal has data to show
    if (Object.keys(trackData).length !== 0) {
      setAudioSrc(trackData);
      setInitialData(trackData);
    }
  }, [trackData]);

  useEffect(() => {
    if (Object.keys(dataToUpdate).length !== 0) {
      validateTrack(dataToUpdate, !!trackData?._id);
    }
  }, [dataToUpdate]);

  // Audio comes from a new file or from a URI
  const setAudioSrc = (trackData) => {
    if (Object.prototype.hasOwnProperty.call(trackData, "newFiles")) {
      if (audioUrl !== null) setAudioUrl(null);
      setAudioFile(trackData.newFiles.fileList[0]);
    } else {
      if (audiofile !== null) setAudioFile(null);
      const dataFromAudioURI = {
        url: "",
        filename: "",
        format: "",
      };
      if (trackData?.asset?.length) {
        dataFromAudioURI.url = trackData?.s3_url || "";
        dataFromAudioURI.filename = trackData?.asset[0]?.filename || "";
        dataFromAudioURI.format = trackData?.asset[0]?.format || "";
      }

      setAudioUrl(dataFromAudioURI);
    }
  };

  /**
   * This is an awkward way to set the initial data
   * but its the best way to set on new tracks
   * also this should only run the first time
   */
  const setInitialData = (trackData) => {
    if (Object.keys(dataToUpdate).length !== 0) return;
    if (Object.hasOwnProperty.call(trackData, "_id")) return;

    dataToUpdateDispatcher({
      type: "directChange",
      payload: {
        value: {
          clip_length: "00:00",
          clip_start_time: "00:00",
          title_length: "00:00",
          number: numberTracks + 1 || 1,
          permission: [
            {
              type: "track_sale",
              enabled: true,
              country: ["WW"],
            },
            {
              type: "stream",
              enabled: true,
              country: ["WW"],
            },
            {
              type: "download",
              enabled: true,
              country: ["WW"],
            },
          ],
          type: "original",
          // This a default value, the real data is sended trough the release
          price_tier: [priceTier],
          copyright: releaseData?.copyright,
          phonographic: releaseData?.phonographic,
          rights_holder: releaseData?.rights_holder,
        },
      },
    });
  };

  const cleanState = () => {
    dataToUpdateDispatcher({ type: "cleanState", payload: {} });
  };

  // the dispatch don't die when the component is unmounted ¯\_(ツ)_/¯
  const handleModalClose = () => {
    cleanState();
    handleCancel();
  };

  const handleModalSave = () => {
    const errors = validateTrack(dataToUpdate, !!trackData?._id);
    if (errors.length === 0) {
      const newTrackData = {
        ...dataToUpdate,
        _id: trackData?._id || "new_" + new Date().toISOString(),
      };
      if (audiofile) newTrackData.newFiles = { fileList: [audiofile] };

      cleanState();
      handleSave(newTrackData);
    }
  };

  const drawValidationErrors = () => {
    if (validateTrackErrorMessages.length === 0) return null;
    const errorsLength = validateTrackErrorMessages.length;

    let withCollapse = null;
    if (errorsLength > 3) {
      const items = [];
      for (let index = 3; index < errorsLength; index++)
        items.push(
          <Alert
            key={"track-error-" + index}
            message={validateTrackErrorMessages[index]}
            type="error"
            showIcon
          />
        );

      withCollapse = (
        <Collapse defaultActiveKey={[]}>
          <Panel header={errorsLength - 3 + " errors more"} key="1">
            {items}
          </Panel>
        </Collapse>
      );
    }

    const result = [];
    const end = errorsLength > 3 ? 3 : errorsLength;
    for (let i = 0; i < end; i++)
      result.push(
        <Alert
          key={"track-error-" + i}
          message={validateTrackErrorMessages[i]}
          type="error"
          showIcon
        />
      );

    return (
      <>
        {result}
        {withCollapse}
      </>
    );
  };

  return (
    <Modal
      idModal="addArtistAcc"
      destroyOnClose
      maskClosable={false}
      handleCancel={handleModalClose}
      isModalVisible={modalVisible}
      addclassName="modalTrackInfo"
      title={
        <div className="modal-title-artist">
          {dictionary.viewTrack} &quot;{trackData?.title || ""}&quot;
        </div>
      }
      body={
        <div className="forms track-detail-form">
          <ISRCSetter
            trackId={trackData._id}
            isrc={trackData.isrc}
            disabled={!isEditMode}
            onSuccess={(isrc) => {
              const updatedTracks = tracks.map((track) => {
                if (track._id === trackData._id) {
                  track.isrc = isrc;
                }
                return track;
              });
              setTracks(updatedTracks);
            }}
            setShowAddUpcIsrcModal={setShowAddUpcIsrcModal}
          />
          <br />
          <AudioPlayer
            replaceMediaChangeHandler={triggerUpdateAudioUpload}
            dataFromFile={audiofile}
            dataFromURI={audioUrl}
          />
          <br />

          <TrackGeneralInformation
            dataToUpdateDispatcher={dataToUpdateDispatcher}
            dataToUpdate={dataToUpdate}
            trackData={trackData}
            isEditMode={isEditMode}
          />
          <TrackContributors
            labelId={labelId}
            dataToUpdateDispatcher={dataToUpdateDispatcher}
            trackData={trackData}
            dataToUpdate={dataToUpdate}
            isEditMode={isEditMode}
          />
          <TrackCopyright
            dataToUpdateDispatcher={dataToUpdateDispatcher}
            dataToUpdate={dataToUpdate}
            trackData={trackData}
            isEditMode={isEditMode}
          />
          {/*JSON.stringify(dataToUpdate)*/}
          <br />
          {drawValidationErrors()}
        </div>
      }
      footer={
        <div className="modalAdd-btns">
          <Button
            key="cancel"
            size="sm"
            className="back-btn"
            text={isEditMode ? dictionary.cancel : dictionary.close}
            onClick={handleModalClose}
          />
          {isEditMode ? (
            <Button
              key="submit"
              size="md"
              disabled={Object.keys(dataToUpdate).length === 0 || !trackIsValid}
              text={dictionary.save}
              className="footer-btn"
              onClick={handleModalSave}
            />
          ) : null}
        </div>
      }
    />
  );
};

export default TrackCrudModal;
