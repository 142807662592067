import React, { useContext } from "react";
import { LanguageContext } from "../../../context/Language";
import Modal from "../../Modal";
import Button from "../../Buttons/GeneralButton";

const ModalStatus = ({
  statusData,
  hideModalStatus,
  statusUser
}) => {

  const { dictionary } = useContext(LanguageContext);

  return (
    <Modal
      destroyOnClose
      maskClosable={false}
      idModal="softDelete"
      isModalVisible={statusData.show}
      handleCancel={hideModalStatus}
      title={<div className="modal-title">{dictionary[statusData.type]}</div>}
      body={
        <div>
          {statusData.type === "disable" ?
          dictionary.disabledMessageUserName.replace(
            "--user--",
            statusData.name
          )
        : dictionary.activeMessageUserName.replace(
            "--user--",
            statusData.name
          )}
        </div>
      }
      footer={
        <div className="modalAdd-btns">
          <Button
            key="cancel"
            size="sm"
            className="back-btn"
            text={dictionary.cancel}
            onClick={hideModalStatus}
          />
          <Button
            key="submit"
            size="md"
            text={dictionary[statusData.type]}
            disabled={statusData.hasRelease > 0}
            className="footer-btn"
            onClick={() => statusUser(statusData.userid, statusData.type)}
          />
        </div>
      }
    />
  );
};

export default ModalStatus;
