import React, { useContext, useState, useEffect } from "react";
import { Divider } from "antd";
import { Link } from "react-router-dom";
import { UserContext } from "../../../context/User";
import { LanguageContext } from "../../../context/Language";
import moment from "moment";
import Loader from "../../Loader";
import Button from "../../Buttons/GeneralButton";
import PaymentMethodSelector from "./PaymentMethodSelector";
import "../set.css";

const Payment = () => {
  const { dictionary } = useContext(LanguageContext);
  const { membership } = useContext(UserContext);
  const [loader, setLoader] = useState(false);
  const [showPaymentEditModal, setShowPaymentEditModal] = useState(false);
  const creditCard = "XXXX XXXX XXXX ";
  const [membershipData, setMembData] = useState({
    planPrice: "",
    plan: "",
    secondary: "",
  });

  useEffect(() => {
    if (membership) {
      setLoader(true);
      if (membership.plan === "plus") {
        setMembData({
          ...membershipData,
          plan: "Priam Plus",
          secondary: " Priam Core",
          planPrice: dictionary.free,
        });
      } else if (membership.plan === "core") {
        setMembData({
          ...membershipData,
          plan: "Priam Core",
          secondary: " Priam Plus",
          planPrice:
            "$" +
            membership.price +
            " " +
            membership.currency +
            " / " +
            dictionary[membership.period],
        });
      }
    }
    setLoader(false);
    // eslint-disable-next-line
  }, [membership]);

  const renderPlusBenefits = () => {
    let benefitsP = [];
    if (benefitsP.length < 1) {
      for (var i = 0; i < 8; i++) {
        benefitsP.push(<li key={i}>{dictionary.plus.benefit[i]}</li>);
      }
    }
    return benefitsP;
  };

  const renderCoreBenefits = () => {
    let benefitsC = [];
    if (benefitsC.length < 1) {
      for (var i = 0; i < 8; i++) {
        benefitsC.push(<li key={i}>{dictionary.core.benefit[i]}</li>);
      }
    }
    return benefitsC;
  };

  const handleShowPaymentEditModal = (e) => {
    if (e) e.preventDefault();
    setShowPaymentEditModal(!showPaymentEditModal);
  };

  const actualBenefits = () => {
    if (membership.plan === "core") return renderCoreBenefits();
    else return renderPlusBenefits();
  };

  const secondaryPlan = () => {
    if (membership.plan === "core"){
      return (
        <div className="card">
          {dictionary.priamPlans.descriptionPlus}
          <ul>{renderPlusBenefits()}</ul>
        </div>
      );
    }else{
      return (
        <div className="card">
          {dictionary.priamPlans.descriptionCore}
          <ul>{renderCoreBenefits()}</ul>
        </div>
      );
    }
  };

  const paymentEditModal = showPaymentEditModal ? (
    <PaymentMethodSelector
      show={showPaymentEditModal}
      defaultPaymentId={membership?.payment_method?.id}
      close={handleShowPaymentEditModal}
    />
  ) : null;

  return (
    <div className="content-set pay">
      {loader ? <Loader /> : null}
      {membership ? (
        <React.Fragment>
          <h2>
            {dictionary.currPlan.replace("--plan--", membershipData.plan)}
          </h2>
          {membership.is_main ? (
            <React.Fragment>
              {paymentEditModal}
              <div className="data">
                {dictionary.price}:&nbsp;
                <div className="card">{membershipData.planPrice}</div>
              </div>
              {membership.plan === "core" ? (
                <>
                  <div className="data">
                    {dictionary.paymentMethod}:&nbsp;
                    <div className="card">
                      {membership?.payment_method
                        ? creditCard + membership?.payment_method?.last4
                        : dictionary.none}
                    </div>
                    <Button
                      text={dictionary.update}
                      size="xs"
                      onClick={handleShowPaymentEditModal}
                      className="update"
                    />
                  </div>
                  <div className="data">
                    {dictionary.renewalDate}:&nbsp;
                    <div className="card">
                      {moment(membership.renewal_date).format("DD/MM/YYYY") ||
                        ""}
                    </div>
                    <div className="curr-benefits">
                      <div>{dictionary.yourCurrentBenefits}:</div>
                      {actualBenefits()}
                    </div>
                  </div>
                </>
              ) : null}
              <Divider />
              <div className="data bg">
                {dictionary.priamPlans.switch}
                <b>{membershipData.secondary}</b>?
                <Link
                  to="https://help.priamdigital.com/portal/en/newticket?departmentId=152968000000007061"
                  target="_blank"
                >
                  <div className="contact">
                    {dictionary.priamPlans.contactUs}
                  </div>
                </Link>
              </div>
              <div className="data sec-plan">
                {dictionary.priamPlans.whatIs}
                <b>{membershipData.secondary}</b>?
              </div>
              {secondaryPlan()}
            </React.Fragment>
          ) : null}
        </React.Fragment>
      ) : null}
    </div>
  );
};

export default Payment;
