import React, { useContext, useState } from "react";
import { Button, Row, Col, Input } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { LanguageContext } from "../../../../context/Language";
import Date from "../../../Form/Date";
import moment from "moment";
import MarkIcon from "../../../../components/Icon/MarkIcon";
import "./copyright.css";

const Copyright = ({ data, dataToUpdate, dispatch, isEditMode }) => {

  const { dictionary } = useContext(LanguageContext);
  const [enableEdit, setEnableEdit] = useState(isEditMode);

  const columnsRights = (title, info) => {
    return (
      <div className="right-column">
        <sub>{title}</sub>
        {info}
      </div>
    );
  };

  const toggleEditMode = () => {
    setEnableEdit(!enableEdit);
  };

  return (
    <div className="rights-box">
      <div className="title">
        <h1>{dictionary.copyright}</h1>
        {isEditMode ? (
          <Button
            className={"edit_button" + (enableEdit ? " active" : "")}
            shape="circle"
            icon={<MarkIcon />}
            onClick={() => toggleEditMode()}
          />
        ) : null}
      </div>
      <div className="rights-container">
        {enableEdit ? (
          <Row className="gutter-row">
            <div className="title-edit-right">{dictionary.rights}</div>
            <Col xs={24} sm={24} md={16} lg={16} className="col1 rights-col">
              <label className="allWidth">(C) {dictionary.info}</label>
              <Input
                onChange={(e) => {
                  dispatch({
                    type: "objectChange",
                    payload: {
                      name: "copyright",
                      original: data.copyright,
                      value: dataToUpdate.copyright
                        ? {
                            data: e.target.value,
                            year: dataToUpdate?.copyright?.year,
                          }
                        : {
                            data: e.target.value,
                            year: data?.copyright?.year,
                          },
                    },
                  });
                }}
                required={true}
                defaultValue={
                  dataToUpdate?.copyright?.data ?? data?.copyright?.data
                }
                value={dataToUpdate?.copyright?.data ?? data?.copyright?.data}
                className="input-general"
              />
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} className="col2 rights-col">
              <Date
                onChange={(e) => {
                  dispatch({
                    type: "objectChange",
                    payload: {
                      name: "copyright",
                      original: data.copyright,
                      value: dataToUpdate.copyright
                        ? {
                            data: dataToUpdate?.copyright?.data,
                            year: moment(e.toISOString()).format("YYYY"),
                          }
                        : {
                            data: data?.copyright?.data,
                            year: moment(e.toISOString()).format("YYYY"),
                          },
                    },
                  });
                }}
                picker="year"
                format="YYYY"
                required={true}
                value={dataToUpdate?.copyright?.data || data?.copyright?.year}
                suffixIcon={<DownOutlined />}
                label={"(C) " + dictionary.year}
              />
            </Col>
          </Row>
        ) : (
          columnsRights(
            dictionary.rights,
            "© " +
              (dataToUpdate?.copyrightData || data?.copyright?.data) +
              " " +
              (dataToUpdate?.copyrightYear || data?.copyright?.year)
          )
        )}

        {enableEdit ? (
          <Row className="gutter-row">
            <div className="title-edit-right">{dictionary.phonogram}</div>
            <Col xs={24} sm={24} md={16} lg={16} className="col1 rights-col">
              <label className="allWidth">(P) {dictionary.info}</label>
              <Input
                onChange={(e) => {
                  dispatch({
                    type: "objectChange",
                    payload: {
                      name: "phonographic",
                      original: data.phonographic,
                      value: dataToUpdate.phonographic
                        ? {
                            data: e.target.value,
                            year: dataToUpdate?.phonographic?.year,
                          }
                        : {
                            data: e.target.value,
                            year: data?.phonographic?.year,
                          },
                    },
                  });
                }}
                required={true}
                defaultValue={
                  dataToUpdate?.phonographic?.data ?? data?.phonographic?.data
                }
                value={
                  dataToUpdate?.phonographic?.data ?? data?.phonographic?.data
                }
                className="input-general"
              />
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} className="col2 rights-col">
              <Date
                onChange={(e) => {
                  dispatch({
                    type: "objectChange",
                    payload: {
                      name: "phonographic",
                      original: data.phonographic,
                      value: dataToUpdate.phonographic
                        ? {
                            data: dataToUpdate?.phonographic?.data,
                            year: moment(e.toISOString()).format("YYYY"),
                          }
                        : {
                            data: data?.phonographic?.data,
                            year: moment(e.toISOString()).format("YYYY"),
                          },
                    },
                  });
                }}
                picker="year"
                required={true}
                format="YYYY"
                value={
                  dataToUpdate?.phonographic?.year || data?.phonographic?.year
                }
                suffixIcon={<DownOutlined />}
                label={"(P) " + dictionary.year}
                className="datePick"
              />
            </Col>
          </Row>
        ) : (
          columnsRights(
            dictionary.phonogram,
            "Ⓟ " +
              (dataToUpdate?.phonographicData || data?.phonographic?.data) +
              " " +
              (dataToUpdate?.phonographicYear || data?.phonographic?.year)
          )
        )}

        {enableEdit ? (
          <Row className="gutter-row">
            <div className="title-edit-right">{dictionary.rightsHolder}</div>
            <Col xs={24} sm={24} md={24} lg={24} className="rights-col">
              <Input
                onChange={(e) => {
                  dispatch({
                    type: "simpleChange",
                    payload: {
                      name: "rights_holder",
                      original: data?.rights_holder,
                      value: e.target.value,
                    },
                  });
                }}
                name="holder"
                required={true}
                defaultValue={
                  dataToUpdate?.rights_holder ?? data?.rights_holder
                }
                label={dictionary.rightsHolder}
                className={"input-general"}
              />
            </Col>
          </Row>
        ) : (
          columnsRights(
            dictionary.rightsHolder,
            dataToUpdate.rights_holder || data?.rights_holder
          )
        )}
      </div>
    </div>
  );
};

export default Copyright;
