import React, { createContext, useState, useEffect, useMemo } from "react";
import Spanish from "./lang/es.json";
import English from "./lang/en.json";
import { IntlProvider } from "react-intl";
import Language from "../../classes/language/language";
import moment from "moment";
export const LanguageContext = createContext();

const local = navigator.language;
let lang = English;
let monthNames = Array.from({ length: 12 }, (e, i) =>
  moment().month(i).format("MMMM")
);
if (local.includes("es-")) {
  lang = Spanish; 
  monthNames = lang.periods.months;
}

export const LanguageProvider = ({ children }) => {
  const [locale, setLocale] = useState(local);
  const [languagesList, setLanguagesList] = useState([]);
  const [dictionary, setMessages] = useState(lang);

  const setLanguagesState = () => {
    Language.languages()
      .then((langData) => {
        if (langData.status === 200) setLanguagesList(langData.body.languages);
        else setLanguagesList([]);
      })
      .catch((err) => {
        console.log(err);
        setLanguagesList([]);
      });
  };

  // Options for language select
  const languageOptions = useMemo(() => {
    return languagesList.map((language) => {
      return { value: language._id, label: language.name };
    });
  }, [languagesList]);

  useEffect(() => {
    setLanguagesState();
  }, []);

  const selectLanguage = (e) => {
    const newLocale = e.target.value;
    setLocale(newLocale);
    if (newLocale === "en") {
      setMessages(English);
    } else if (newLocale === "es") {
      setMessages(Spanish);
    }
  };

  return (
    <LanguageContext.Provider
      value={{
        locale,
        dictionary,
        selectLanguage,
        setLanguagesState,
        languageOptions,
        languagesList,
        monthNames,
      }}
    >
      <IntlProvider dictionary={dictionary} locale={locale}>
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
};
