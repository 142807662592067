import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { LanguageContext } from "../../../../context/Language";
import Button from "../../../Buttons/GeneralButton";
import LeftArrowIcon from "../../../../components/Icon/LeftArrowIcon";
import "./actions.css";

const Actions = ({
  isLoading,
  dataToUpdate,
  sendDataToUpdate
  }) => {

  const { dictionary } = useContext(LanguageContext);

  return (
    <div className="actions-box">
      <div className="actions-release">
        <Link to="/users">
          <Button
            key="deny"
            size="ms"
            icon={<LeftArrowIcon />}
            className="return"
            text={dictionary.return}
            disabled={isLoading}
          />
        </Link>
        {Object.keys(dataToUpdate).length > 0 ? (
          <Button
            key="approve"
            size="ms"
            className="save"
            text={dictionary.save}
            onClick={sendDataToUpdate}
            disabled={isLoading}
          />
        ) : null}
      </div>
    </div>
  );
};

export default Actions;
