import axios from "axios";

export default class BaseCalls {
  constructor() {
    if (this.constructor === BaseCalls) {
      throw new Error("BaseCalls is abstract");
    }
    this.axios = axios.create({
      baseURL: "/api/" + process.env.REACT_APP_API_VERSION,
      timeout: 30000,
    });
    this.route = "";
    this.body = {};
    this.params = {};
    this.config = {};
  }

  /**
   * @param {string | Object | ArrayBuffer | ArrayBufferView | URLSearchParams | FormData | File | Blob} data
   */
  setBody(data) {
    this.body = data;
  }

  /**
   * @param {string} route
   */
  setRoute(route) {
    if (typeof route === "string") this.route = route;
  }

  setValues = (route, body = {}, params = {}, config = {}) => {
    this.route = route;
    this.body = body;
    this.params = params;
    this.config = config;
  };

  evalResponse = (resp) => {
    return {
      body: resp.data,
      status: resp.status,
      errors: resp.data.errors ? resp.data.errors : null,
    };
  };

  evalError = (error) => {
    if (error.response) {
      return {
        body: error.response.data ? error.response.data : null,
        status: error.response.status,
        errors: error.response.data
          ? error.response.data.errors
            ? error.response.data.errors
            : error.response.data
          : error.response,
      };
    } else if (error.request) {
      return {
        body: null,
        status: "BAD_REQUEST",
        errors: error.request,
      };
    } else {
      return {
        body: null,
        status: "ERROR_UNKOWN",
        errors: error.message,
      };
    }
  };

  doPost = async () => {
    return await this.axios
      .post(this.route, this.body, { params: this.params })
      .then((resp) => {
        return this.evalResponse(resp);
      })
      .catch((error) => {
        return this.evalError(error);
      });
  };

  /*
	async post(data = {}) {
		const route = data?.route ?? this.route;
		const body = data?.body ?? this.body;
		const config = data?.config ?? {params: this.params, ...this.config};
		return this.axios.post(route, body, config);
	}
	*/

  doPatch = async () => {
    return await this.axios
      .patch(this.route, this.body, { params: this.params })
      .then((resp) => {
        return this.evalResponse(resp);
      })
      .catch((error) => {
        return this.evalError(error);
      });
  };

  doGet = async () => {
    return await this.axios
      .get(this.route, { params: this.params })
      .then((resp) => {
        return this.evalResponse(resp);
      })
      .catch((error) => {
        return this.evalError(error);
      });
  };

  doDelete = async () => {
    return await this.axios
      .delete(this.route, { params: this.params, data: this.body })
      .then((resp) => {
        return this.evalResponse(resp);
      })
      .catch((error) => {
        return this.evalError(error);
      });
  };

  doPut = async () => {
    return await this.axios
      .put(this.route, this.body, { ...this.config })
      .then((resp) => {
        return this.evalResponse(resp);
      })
      .catch((error) => {
        return this.evalError(error);
      });
  };
}
