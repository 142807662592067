import React, { useContext, useState, useEffect } from "react";
import { notification } from "antd";
import { LanguageContext } from "../../context/Language";
import { StopOutlined } from "@ant-design/icons";
import Admin from "../../classes/admin/admin.jsx";
import TableContracts from "../../components/Contracts/TableContracts/TableContracts.jsx";
import ModalContractStatus from "../../components/Contracts/ModalContractStatus/ModalContractStatus.jsx";
import "./contract.css";

const Contracts = () => {
  const { dictionary } = useContext(LanguageContext);
  const [loadList, setLoading] = useState(false);
  const [listContractAdmin, setContractAdmin] = useState([]);
  const [statusData, setstatusData] = useState({
    contractid: "",
    show: false,
    type: "",
  });
  const [where, setWhere] = useState({});
  const [order, setOrder] = useState({});
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [totalData, setTotalData] = useState(0);

  useEffect(() => {
    getContracts();
  }, [where, order, limit, offset]);

  const columns = [
    {
      title: dictionary.mainLabelArtist,
      dataIndex: "name",
      className: "title-category",
    },
    {
      title: dictionary.contract + "(s)",
      dataIndex: "contracts",
      className: "title-category",
    },
    {
      title: dictionary.assignedContract,
      dataIndex: "assigned_contract",
      className: "title-category",
    },
    {
      title: dictionary.status,
      dataIndex: "status",
      className: "title-category",
    },
    {
      title: dictionary.payee + " ID",
      dataIndex: "payee_id",
      className: "title-category",
    },
    {
      title: dictionary.editValues,
      dataIndex: "editValues",
      className: "title-category",
    },
  ];

  const getContracts = () => {
    setLoading(true);
    setContractAdmin([]);
    try {
      Admin.contracts(where, order, limit, offset).then((resp) => {
        if (resp.status === 200) {
          setContractAdmin(resp.body.contracts.data);
          setTotalData(resp.body.contracts.count);
        } else {
          setContractAdmin([]);
          notification.open({
            message: dictionary.errorGeneral,
          });
        }
      });
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const showModalContractStatus = (contract_id = "", type) => {
    setstatusData({
      contractid: contract_id,
      show: true,
      type: type,
    });
  };

  const hideModalStatus = () => {
    setstatusData({
      contractid: "",
      show: false,
      type: "",
    });
  };

  const errorNotification = () => {
    notification.error({
      message: dictionary.errorGeneral,
      duration: 3,
      placement: "topRight",
    });
  };

  const successDisNotification = () => {
    notification.success({
      message: dictionary.disableContractSuccess,
      duration: 3,
      placement: "topRight",
      icon: <StopOutlined style={{ color: "#f28f8f" }} />,
    });
  };

  const successNotification = () => {
    notification.success({
      message: dictionary.enableContractSuccess,
      duration: 3,
      placement: "topRight",
    });
  };

  const statusContract = (contractId, type) => {
    let newStatus = type === "disable" ? false : true;
    if (newStatus) {
      Admin.enableContract(contractId)
        .then((res) => {
          if (res.status === 200) {
            successNotification();
            getContracts();
          }
        })
        .catch(() => {
          errorNotification();
        });
    } else {
      Admin.disableContract(contractId)
        .then((res) => {
          if (res.status === 200) {
            successDisNotification();
            getContracts();
          }
        })
        .catch(() => {
          errorNotification();
        });
    }
    setLoading(false);
    hideModalStatus();
  };

  const updatePage = (page) => {
    setOffset((page - 1) * limit);
  };

  return (
    <React.Fragment>
      <div className="container-admin">
        <TableContracts
          isAdmin={true}
          error={false}
          remove={false}
          released={true}
          canDelete={true}
          loadList={loadList}
          setLoading={setLoading}
          isUser={true}
          subjects={listContractAdmin || []}
          list={listContractAdmin || []}
          setNewList={setContractAdmin}
          title={dictionary.users}
          getContracts={getContracts}
          columns={columns}
          updatePage={updatePage}
          limit={limit}
          setLimit={setLimit}
          offset={offset}
          setWhere={setWhere}
          totalData={totalData}
          showModalContractStatus={showModalContractStatus}
        />
        <ModalContractStatus
          statusData={statusData}
          hideModalStatus={hideModalStatus}
          statusContract={statusContract}
        />
      </div>
    </React.Fragment>
  );
};

export default Contracts;
