import React, { useContext, useEffect, useState, useMemo } from "react";
import { AppContext } from "../../../../../context/App";
import { LanguageContext } from "../../../../../context/Language";
import MicIcon from "../../../../../components/Icon/MicIcon";
import GenreIcon from "../../../../../components/Icon/GenreIcon";
import EditUserIcon from "../../../../../components/Icon/EditUserIcon";
import ReleaseElement from "../../../Create/Release/ReleaseElement";
import Button from "../../../../Buttons/GeneralButton";
import MarkIcon from "../../../../../components/Icon/MarkIcon";
import TrackCrudModal from "../../Modals/TrackCrudModal";
const Summary = ({ dataTrack = [], editMode }) => {

  const { dictionary } = useContext(LanguageContext);
  const { genres, subGenres } = useContext(AppContext);
  const [allcontributors, setContributors] = useState([]);
  const [modalVisible, ismodalVisible] = useState(false);

  useEffect(() => {
    getContributors(dataTrack);
  }, [dataTrack]);

  const printRoles = useMemo(() => {
    return (roles) => {
      const rolesCollaborator = [];
      if (Array.isArray(roles)) {
        const updatedRoles = roles.filter((role) => role !== "producer");
        for (let i = 0; i < updatedRoles.length; i++) {
          rolesCollaborator.push(
            dictionary.artistType[updatedRoles[i]] +
              (i === updatedRoles.length - 1 ? "" : ", ")
          );
        }
      }
      return rolesCollaborator;
    };
  }, [dictionary.artistType]);

  const orderedList = (list = [], roles = false, isCollaborator = false) => {
    const count = list.length;
    if (Array.isArray(list)) {
      return list.map((el, index) => {
        let roleList = printRoles(el.role);
        if (count === index + 1 && count > 1) {
          return (
            <React.Fragment key={index}>
              {" "}
              {dictionary.and}{" "}
              {roles ? (
                dictionary.artistType[el]
              ) : isCollaborator ? (
                <React.Fragment>
                  {el.name} <div className="rol-list">({roleList})</div>
                </React.Fragment>
              ) : (
                el.name
              )}
            </React.Fragment>
          );
        } else if (count - 2 === index && count > 1) {
          return (
            <React.Fragment key={index}>
              {roles ? (
                dictionary.artistType[el]
              ) : isCollaborator ? (
                <React.Fragment>
                  {el.name} <div className="rol-list">({roleList})</div>
                </React.Fragment>
              ) : (
                el.name
              )}
            </React.Fragment>
          );
        } else if (count < 3) {
          return (
            <React.Fragment key={index}>
              {roles ? (
                dictionary.artistType[el]
              ) : isCollaborator ? (
                <React.Fragment>
                  {el.name} <div className="rol-list">({roleList})</div>
                </React.Fragment>
              ) : (
                el.name + " "
              )}
            </React.Fragment>
          );
        } else {
          return (
            <React.Fragment key={index}>
              {roles ? (
                dictionary.artistType[el]
              ) : isCollaborator ? (
                <React.Fragment>
                  {el.name} <div className="rol-list">({roleList})</div>,&nbsp;
                </React.Fragment>
              ) : (
                el.name + ", "
              )}
            </React.Fragment>
          );
        }
      });
    }
  };

  const artistsPreview = (listArtist = []) => {
    let onlyMain = [];
    onlyMain = listArtist?.filter((el) => el.role.includes("main"));
    let onlyFeat = [];
    onlyFeat = listArtist?.filter((el) => el.role.includes("feature"));
    let countFeat = onlyFeat?.length;
    let setMains = orderedList(onlyMain);
    let setFeat = onlyFeat
      ? onlyFeat?.map((el, index) => {
          if (countFeat === index + 1 && countFeat > 1) {
            return " " + dictionary.and + " " + el.name;
          } else if (countFeat < 3) {
            return dictionary.featuring + el.name + " ";
          } else if (index === 0) {
            return dictionary.featuring + el.name + ", ";
          } else {
            return el.name + ", ";
          }
        })
      : [];
    setMains.push(setFeat);
    return setMains;
  };

  const getGenreName = (genreId) => {
    const gnr = genres.find((item) => item._id === genreId);
    return gnr ? gnr.name : "";
  };

  const getSubgenreName = (subgenreId) => {
    const subgenre = subGenres.find((item) => item._id === subgenreId);
    return subgenre ? subgenre.name : "";
  };

  const setGenres = (dataGenres = []) => {
    let genreNames = [];
    if (dataGenres.length !== 0) {
      dataGenres.map((item, i) => {
        const genreName = getGenreName(item.genre_id);
        const subgenreName = getSubgenreName(item.subgenre_id);
        if (i > 0) {
          genreNames += ", ";
        }
        genreNames += subgenreName ? genreName + "/" + subgenreName : genreName;
      });
    }
    return genreNames;
  };

  const getDisplayPublishers = (producers = []) => {
    let publisherNames = "";
    producers.forEach((el, i) => {
      if (el.role === "publisher") {
        if (i > 0) {
          publisherNames += ", ";
        }
        publisherNames += el.name;
      }
    });
    return publisherNames;
  };

  const getDisplayContributors = (producers, writers, participants) => {
    let contributorsNames = "";
    if (producers) {
      producers.forEach((el) => {
        if (el.role !== "publisher") {
          contributorsNames += el.name + " (" + el.role + ")";
          if (el.length > 0) {
            contributorsNames += ", ";
          }
        }
      });
    }
    if (writers) {
      writers.forEach((el) => {
        contributorsNames += ", " + el.name + " (" + el.role + ")";
      });
    }
    if (participants) {
      participants.forEach((el) => {
        contributorsNames += ", " + el.name;
      });
    }
    return contributorsNames;
  };

  const getContributors = (data) => {
    const publishers = getDisplayPublishers(data.producers);
    const contributors = getDisplayContributors(
      data.producers,
      data.writers,
      data.participants
    );
    setContributors(
      <React.Fragment>
        {publishers ? (
          <ReleaseElement
            title={dictionary.publishers}
            value={publishers}
            className="track-publisher"
          />
        ) : null}
        {contributors ? (
          <ReleaseElement
            title={dictionary.contributors}
            value={contributors}
            className="track-publisher"
          />
        ) : null}
      </React.Fragment>
    );
  };

  const dataCard = (icon = null, section = null, data = null) => {
    return (
      <div className="row-preview">
        <div className="icon-head">{icon}</div>
        <div className="info-head">
          <div className="sub">{section}</div>
          <div className="names">{data}</div>
        </div>
      </div>
    );
  };

    const openTrackModal = () => {
        ismodalVisible(true);
    };

    const handleSave = () => {
        ismodalVisible(false);
    };

    const handleCancel = () => {
        ismodalVisible(false);
    };

    const copyArtistName = () => {
        //pending copy artist function
      }

  return (
    <div className="trackDetail">
      <h3>{dataTrack.title}</h3>
      <div className="track-box">
        {editMode ? (
          <div className="mark-icon-edit">
            <MarkIcon onClick={openTrackModal} />
          </div>
        ) : null}
        {dataCard(
          <MicIcon />,
          dictionary.artists,
          artistsPreview(dataTrack.artists)
        )}
        {dataCard(<GenreIcon />, dictionary.genre, setGenres(dataTrack.genres))}
        {dataCard(
          <EditUserIcon />,
          dictionary.publishersAndContributors,
          allcontributors
        )}
        {editMode ? (
          <Button
            size="fullWidth"
            className="copy-artist-btn"
            text={dictionary.copyArtist}
            onClick={copyArtistName}
          />
        ) : null}
      </div>
      {modalVisible ?
        <TrackCrudModal
            isEditMode={editMode}
            trackData={dataTrack}
            handleSave={handleSave}
            modalVisible={modalVisible}
            handleCancel={handleCancel}
        />
      : null}
    </div>
  );
};

export default Summary;
