import React, { useContext, useMemo } from "react";
import { Col, Row, Typography, Table } from "antd";
import { LanguageContext } from "../../../../context/Language";
import moment from "moment";

const ArtistsList = ({ artists }) => {

  const { dictionary } = useContext(LanguageContext);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      className: "id-category",
    },
    {
      title: dictionary.name,
      dataIndex: "name",
      className: "title-category",
    },
    {
      title: dictionary.label,
      dataIndex: "desc",
      className: "title-category",
    },
    {
      title: dictionary.createdAt,
      dataIndex: "createdAt",
      className: "title-category",
    },
    {
      title: dictionary.updated,
      dataIndex: "updated",
      className: "title-category",
    },
  ];

  const dataSource = useMemo(() => {
    let listArray = [];
    artists.map((el, index) => {
      let name = el.name ? el.name : dictionary.noTitle;
      let labelName = el.label
        ? el.label.name
        :  "-";
      let created = el.createdAt
        ? moment.utc(el.createdAt).startOf("day").format("YYYY/MM/DD")
        : null;
      let updated = el.updatedAt
        ? moment.utc(el.updatedAt).startOf("day").format("YYYY/MM/DD")
        : null;
      return listArray.push({
        index: index,
        key: el._id || index,
        id: index + 1,
        name: name,
        desc: labelName,
        createdAt: created,
        updated: updated,
      });
    });
    return (listArray);
  }, [artists]);

  return (
    <div className="general_information user-detls">
      <Row>
        <Col>
          <Typography.Title level={3}>
            {dictionary.artistList}
          </Typography.Title>
        </Col>
      </Row>
      <Row justify="space-around" className="user-div">
        <Col xs={24} lg={24} className="info-one">
          <div className="table-admin inline-contend">
            <Table
              rowKey="index"
              columns={columns}
              pagination={artists.lenght > 10 ? true : false}
              dataSource={dataSource}
              className="content-table-admin"
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ArtistsList;