import ArtistCalls from "../calls/artist/artist.jsx";
import uploadS3Calls from "../calls/amazon/uploadS3.jsx";
import { v4 as uuidv4 } from "uuid";

class Artist {
  create = async (artist, user = null, image = null) => {
    if (image) {
      const imageUrl = await this.uploadImageArtist(image);
      if (imageUrl) {
        artist.image = imageUrl;
      }
    }
    const body = {
      artist: artist,
    };
    if (user) {
      body.user = user;
    }
    const newArtist = await ArtistCalls.create(body);
    if (newArtist.status === 200 && !newArtist.errors) {
      artist._id = newArtist.body.artist.id;
      return artist;
    }
    return null;
  };

  update = async (artist, profile = null, image = null) => {
    if (image && image.type) {
      const imageUrl = await this.uploadImageArtist(image);
      if (imageUrl) {
        artist.image = imageUrl;
      }
    } else if (image && !image.type) {
      artist.image = image;
    }
    const body = {
      artist: artist,
    };
    if (profile) {
      body.artist.profile = profile;
    }
    const updateArtist = await ArtistCalls.update(body);
    if (updateArtist.status === 200 && !updateArtist.errors) {
      artist._id = updateArtist.body.artist.id;
      return artist;
    }
    return null;
  };

  uploadImageArtist = async (image) => {
    const ext = image.name.split(".")[1];
    const fileName = uuidv4() + "." + ext;
    const uploadS3 = new uploadS3Calls("artists", fileName);
    const url = await uploadS3.createSignedUrl(image);
    const uploaded = await uploadS3.uploadFile(url, image, null);
    return uploaded.url || null;
  };

  deleteImageArtist = async (url, artist = {}) => {
    if (artist && artist._id) {
      const body = {
        artist: {
          artist_id: artist._id,
          image: "",
        },
      };
      await ArtistCalls.updateImage(body);
    }

    const fileName = url.substring(url.lastIndexOf("/") + 1);
    const uploadS3 = new uploadS3Calls("artists", fileName);
    const deleted = await uploadS3.deleteFile();
    return !!deleted;
  };

  deleteArtist = async (artistId) => {
    return await ArtistCalls.delete(artistId);
  };
}

export default new Artist();
