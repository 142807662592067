import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Dropdown, Switch } from "antd";
import { ArtistContext } from "../../../../../context/Artist";
import { DeleteOutlined } from "@ant-design/icons";
import { LanguageContext } from "../../../../../context/Language";
import Button from "../../../../Buttons/GeneralButton";
import ArtistIcon from "../../../../Icon/ArtistIcon";
import Search from "../../../../Form/Search";

const DisplayArtist = ({
  list,
  type,
  error,
  remove,
  opened,
  openDrop,
  showModal,
  frontList,
  listArtist,
  frontArtist,
  setArtistList,
  setFrontArtist,
  setDisplayArtist,
}) => {

  const { artists } = useContext(ArtistContext);
  const [peopleList, setPeopleList] = useState([]);

  useEffect(() => {
    if (artists.length) {
      setPeopleList(artists);
      getArtistsUnused();
    }
  }, [artists]);

  const { dictionary } = useContext(LanguageContext);
  const [search, getSearch] = useState(null);

  const onSearch = (searchValue) => {
    getSearch(searchValue.target.value);
    let filteredNames = artists.filter((el) =>
      el.name.toLowerCase().includes(searchValue.target.value.toLowerCase())
    );
    setPeopleList(filteredNames);
  };

  const closeAddArtist = () => {
    openDrop(false);
  };

  const addNewArtist = (newName) => {
    openDrop(false);
    showModal(null, "artist", newName);
  };

  const getArtistsUnused = () => {
    const findEl = [];
    if (list.length > 0) {
      list.forEach((element) => {
        findEl.push(element.artist_id);
      });
    }
    if (peopleList.length !== 0) {
      return peopleList.map((el, index) => {
        if (!findEl.includes(el._id)) {
          return (
            <li
              key={index}
              onMouseUp={closeAddArtist}
              onClick={() => setDisplayArtist(el, "artist")}
            >
              {el.name}
            </li>
          );
        }
      });
    } else {
      return <div className="no-data">{dictionary.noArtists}</div>;
    }
  };

  const items = [
    {
      label: (
        <Search
          name="searcher"
          value={search}
          onSearch={onSearch}
          className="search-add plus-add"
          placeholder={dictionary.search}
        />
      ),
      key: "0",
      className: "searcher-add-artists",
    },
    {
      label: <ul className="add-list">{getArtistsUnused()}</ul>,
      key: "1",
    },
    {
      type: "divider",
    },
    {
      label: (
        <div className="submenu-add">
          <Button
            className="add-btn"
            text={dictionary.addNew}
            onClick={() => addNewArtist(search)}
          />
          <Button
            className="add-btn"
            onClick={() => closeAddArtist()}
            text={dictionary.cancel}
          />
        </div>
      ),
      key: "2",
    },
  ];

  const viewArtists = () => {
    openDrop(!opened);
  };

  const handleMenuClick = (e) => {
    if (e.key === "0") {
      openDrop(true);
    }
  };

  const onChangeSwitch = (checked, el) => {
    if (checked) {
      el.role = "main";
    } else {
      el.role = "feature";
    }
    setArtistList({
      ...listArtist,
      artist: list,
    });
    setFrontArtist({
      ...frontArtist,
      artist: frontList,
    });
  };

  const handleOpenChange = (flag) => {
    openDrop(flag);
  };

  return (
    <Row>
      <Col xs={24} sm={24} md={24} lg={24}>
        <div className="title-add-flex">
          <h2 className="titl-section">{dictionary.artists}</h2>
          <Dropdown
            menu={{ items, onClick: handleMenuClick }}
            trigger={["click"]}
            placement="bottomRight"
            overlayClassName="drop-people"
            onOpenChange={handleOpenChange}
            open={opened}
          >
            <button
              className="new-artist-add clicked btnsm"
              onClick={() => viewArtists()}
            >
              <b>+ </b>
              {dictionary.add} {dictionary.category[type]}
            </button>
          </Dropdown>
        </div>
        <div>
          <ul className="participant-cd">
            {list.map((el, index) => {
              return (
                <li key={index} className="new-artist card-data">
                  <ArtistIcon className="artist-icon" />
                  <div className="name-added">{el.name}</div>
                  <div className="del-participant">
                    <DeleteOutlined
                      onClick={() => remove(type, index, el.name)}
                    />
                  </div>
                  <div className="check-artists">
                    <Switch
                      checked={el.role === "main"}
                      className="general-switch"
                      onChange={(e) => onChangeSwitch(e, el)}
                    />
                    {dictionary.artistType[el.role]}
                  </div>
                </li>
              );
            })}
          </ul>
          {error ? <p className="text-error relative-text">{error}</p> : null}
        </div>
      </Col>
    </Row>
  );
};

export default DisplayArtist;
