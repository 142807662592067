import React, { useContext, useState, useEffect, useRef } from "react";
import { LanguageContext } from "../../../context/Language";
import { UserContext } from "../../../context/User";
import { notification } from "antd";
import Tables from "../Tables";
import DeleteDraft from "../../Dashboard/Releases/ReleaseCard/DeleteDraft";
import Release from "../../../classes/release/release";

const ReleasesList = ({ filter, releases, loadList, getReleases }) => {
  let redirect = useRef("");
  const { dictionary } = useContext(LanguageContext);
  const { permission, type } = useContext(UserContext);
  const [listRelAdmin, setReleaseAdmin] = useState([]);
  const [allFormats, filterFormats] = useState([]);
  const [allStatus, filterStatus] = useState([]);
  const [deleteTitle, deleteRelTitle] = useState(false);
  const [deleteVisible, deleteRelVisible] = useState(false);

  useEffect(() => {
    const sections = Object.keys(releases);
    const all = [];
    sections.forEach((el) => {
      releases[el].forEach((element) => {
        element.step = el;
        all.push(element);
      });
    });
    // all.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    setReleaseAdmin(all);
  }, [releases]);

  useEffect(() => {
    const allRelease = releases.published;
    if (!filter) return null;
    let artistArray = [...allRelease];
    switch (filter) {
      case 1:
        artistArray.sort((a, b) => a.title.localeCompare(b.title));
        break;
      case 2:
        artistArray.sort((a, b) => -1 * a.title.localeCompare(b.title));
        break;
      default:
        break;
    }
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    getFormats();
    getStatus();
    // eslint-disable-next-line
  }, []);

  const showModal = () => {
    redirect.current.click();
  };

  const getAllReleases = () => {
    const all = [];
    try {
      const sections = Object.keys(releases);
      sections.forEach((el) => {
        releases[el].forEach((element) => {
          if (element.title !== null) {
            all.push(element);
          }
        });
      });
      return all;
    } catch (e) {
      return all;
    }
  };

  const columnsAdmin = [
    {
      title: dictionary.cover,
      dataIndex: "details",
      className: "id-category",
    },
    {
      title: dictionary.title,
      dataIndex: "title",
      className: "title-category album",
    },
    {
      title: dictionary.requestedBy,
      dataIndex: "requestedBy",
      className: "title-category",
    },
    {
      title: dictionary.releaseDate,
      dataIndex: "releaseDate",
      className: "title-category",
    },
    // {
    //     title: dictionary.format,
    //     dataIndex: 'format',
    //     className: 'title-category'
    // },
    {
      title: dictionary.status,
      dataIndex: "estatus",
      className: "title-category status-rel",
    },
    {
      title: dictionary.createdAt,
      dataIndex: "createdAt",
      className: "title-category",
    },
    {
      title: dictionary.storesRequested,
      dataIndex: "storesRequested",
      className: "title-category album",
    },
    {
      title: dictionary.details,
      dataIndex: "detail",
      className: "id-category",
    },
  ];

  const columnsUser = [
    {
      title: dictionary.cover,
      dataIndex: "details",
      className: "id-category",
    },
    {
      title: dictionary.title,
      dataIndex: "title",
      className: "title-category album",
    },
    {
      title: dictionary.status,
      dataIndex: "estatus",
      className: "title-category status-rel",
    },
    {
      title: dictionary.releaseDate,
      dataIndex: "releaseDate",
      className: "title-category",
    },
    {
      title: dictionary.createdAt,
      dataIndex: "createdAt",
      className: "title-category",
    },
    {
      title: dictionary.storesRequested,
      dataIndex: "storesRequested",
      className: "title-category album",
    },
    {
      title: dictionary.details,
      dataIndex: "detail",
      className: "id-category",
    },
  ];

  const getFormats = () => {
    const list = [];
    Release.releaseType().then((res) => {
      if (res?.body?.formats === undefined) return null;
      let allformats = Object.values(res.body.formats || []);
      let keys = Object.keys(res.body.formats);
      let i = 0;
      for (let type of allformats) {
        list.push({ _id: keys[i], name: type });
        i++;
      }
      filterFormats(list);
    });
  };

  const getStatus = () => {
    const list = [];
    Release.audiosaladStatus().then((res) => {
      let allstatus = res?.body?.status || [];
      let keys = Object.keys(allstatus);
      let i = 0;
      if (type === "admin") {
        for (i; i < keys.length; i++) {
          list.push({ _id: keys[i], name: dictionary[keys[i]] });
        }
      } else {
        for (i; i < keys.length; i++) {
          if (keys[i] !== "readyToSent") {
            list.push({ _id: keys[i], name: dictionary[keys[i]] });
          }
        }
      }
      filterStatus(list);
    });
  };

  const showDeleteDraft = (id, title) => {
    deleteRelVisible(id);
    deleteRelTitle(title);
  };

  const deleteCancel = () => {
    deleteRelVisible(false);
  };

  const deleteRelease = (id) => {
    try {
      Release.updateDraftStatus(id, false).then((res) => {
        if (res.error) {
          notification.error({
            message: dictionary.errorGeneral,
            duration: 3,
            placement: "topRight",
          });
        } else {
          deleteRelVisible(false);
          notification.success({
            message: dictionary.delRelSuccess,
            duration: 3,
            placement: "topRight",
          });
        }
      });
      getReleases();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <React.Fragment>
      {type === "admin" ? (
        <Tables
          typeTable={2}
          canUpdate={false}
          canDelete={false}
          loadList={loadList}
          isRelease={true}
          subjects={listRelAdmin}
          list={listRelAdmin || []}
          setNewList={setReleaseAdmin}
          title={dictionary.releases}
          getReleases={getReleases}
          getAllReleases={getAllReleases}
          columns={columnsAdmin}
          allFormats={allFormats}
          allStatus={allStatus}
          allReleasesAdmin={true}
          isAdminRels={true}
          statusTag={true}
          section="release"
        />
      ) : (
        <div>
          <Tables
            plus={true}
            error={false}
            typeTable={2}
            remove={false}
            isUserRel={true}
            statusTag={true}
            isRelease={true}
            canDelete={false}
            loadList={loadList}
            redirect={redirect}
            columns={columnsUser}
            allStatus={allStatus}
            showModal={showModal}
            route="/newRelease?r="
            subjects={listRelAdmin}
            allReleasesAdmin={true}
            allFormats={allFormats}
            getReleases={getReleases}
            list={listRelAdmin || []}
            title={dictionary.releases}
            setNewList={setReleaseAdmin}
            getAllReleases={getAllReleases}
            emptyText={dictionary.noReleases}
            showDeleteDraft={showDeleteDraft}
            canAdd={permission?.release?.add || false}
            canUpdate={permission?.release?.update || false}
          />
          {deleteVisible ? (
            <DeleteDraft
              deleteTitle={deleteTitle}
              handleCancel={deleteCancel}
              deleteRelease={deleteRelease}
              deleteVisible={deleteVisible}
            />
          ) : null}
        </div>
      )}
    </React.Fragment>
  );
};

export default ReleasesList;
