import React, { useContext, useEffect, useMemo, useState } from "react";
import { LanguageContext } from "../../../context/Language/index.js";
import { useFieldArray, useFormContext, Controller } from "react-hook-form";
import "../CreateContract.css";
import TextArea from "antd/lib/input/TextArea";
import { Button, Checkbox, Select } from "antd";
import DateInput from "../../../components/Form/Date/index.js";
import CreateCrossContract from "../../../components/Contracts/Create/Overview/CrossContract";
import Input from "../../../components/Form/Input/index.js";
import { AppContext } from "../../../context/App/AppProvider.js";
import Admin from "../../../classes/admin/admin.jsx";

const CreateContractOverview = () => {
  const { control, watch } = useFormContext();
  const { dictionary } = useContext(LanguageContext);
  const {
    currencyContractOptions,
    accountingPeriodContractOptions,
    categoriesContractOptions,
    labelOptions,
    tariffPlansOptions,
  } = useContext(AppContext);
  const {
    fields: crossContracts,
    append: appendCrossContracts,
    remove: removeCrossContracts,
  } = useFieldArray({
    name: "crossContracts",
  });

  const [contracts, setContracts] = useState([]);

  const contractType = watch(`contractType`);

  const showProfit = useMemo(
    () => contractType === "620ab4e35b9836178b35f100",
    [contractType]
  );

  useEffect(() => {
    Admin.contracts().then((resp) => {
      if (resp.status === 200) {
        let contractOptions = [];
        resp.body.contract?.forEach((c) => {
          const curveContracts = c.contracts.filter((cc) => cc.curve_id);
          contractOptions = contractOptions.concat(
            curveContracts.map((cc) => ({
              name: cc.curve_id,
              value: cc.name,
            }))
          );
        });
        setContracts(contractOptions);
      } else {
        setContracts([]);
      }
    });
  }, []);

  const drawCrossContracts = () => {
    return crossContracts.map((cc, index) => {
      return (
        <CreateCrossContract
          key={cc.id}
          data={cc}
          index={index}
          remove={deleteCrossContract}
          contractOptions={contracts}
        />
      );
    });
  };

  const addCrossContract = () => {
    appendCrossContracts({
      when: "",
      contractId: "",
      process: "",
      percentage: "",
    });
  };

  const deleteCrossContract = (index) => {
    removeCrossContracts(index);
  };

  return (
    <div className="form-content">
      <div className="form-column">
        <div className="form-box">
          <h3>Overview</h3>
          <Controller
            control={control}
            name="name"
            rules={{
              required: "Name is required",
            }}
            render={({ field, fieldState }) => (
              <Input
                className={"form-box-input"}
                label={dictionary.name}
                placeholder={dictionary.name}
                required
                {...field}
                error={fieldState.error?.message}
              />
            )}
          />
          <Controller
            control={control}
            name="companyId"
            rules={{
              required: "Company is required",
            }}
            render={({ field, fieldState }) => (
              <Input
                className={"form-box-input"}
                label={"Company"}
                placeholder={dictionary.company}
                required
                {...field}
                error={fieldState.error?.message}
              />
            )}
          />
          <Controller
            control={control}
            name="projectId"
            rules={{
              required: "Project id is required",
            }}
            render={({ field, fieldState }) => (
              <Input
                className={"form-box-input"}
                label={"Project Id"}
                placeholder={dictionary.name}
                required
                {...field}
                error={fieldState.error?.message}
              />
            )}
          />
          <div className={"form-box-select"}>
            <Controller
              control={control}
              name="currency"
              rules={{
                required: "Currency is required",
              }}
              render={({ field, fieldState }) => (
                <React.Fragment>
                  <label className="allWidth">
                    Currency<span>*</span>
                  </label>
                  <Select
                    required={true}
                    className={
                      "fullWidth selector-general" +
                      (fieldState.error ? " input-general-error" : "")
                    }
                    options={currencyContractOptions}
                    {...field}
                  />
                  {fieldState.error ? (
                    <div className="text-error">{fieldState.error.message}</div>
                  ) : null}
                </React.Fragment>
              )}
            />
          </div>
          <div className={"form-box-select"}>
            <Controller
              control={control}
              name="accountingPeriod"
              rules={{
                required: "Accounting Period is required",
              }}
              render={({ field, fieldState }) => (
                <React.Fragment>
                  <label className="allWidth">
                    Accounting Period<span>*</span>
                  </label>
                  <Select
                    className={
                      "fullWidth selector-general" +
                      (fieldState.error ? " input-general-error" : "")
                    }
                    options={accountingPeriodContractOptions}
                    {...field}
                  />
                  {fieldState.error ? (
                    <div className="text-error">{fieldState.error.message}</div>
                  ) : null}
                </React.Fragment>
              )}
            />
          </div>
          <div className={"form-box-select"}>
            <Controller
              control={control}
              name="contractType"
              rules={{
                required: "Contract Type is required",
              }}
              render={({ field, fieldState }) => (
                <React.Fragment>
                  <label className="allWidth">
                    Contract Type<span>*</span>
                  </label>
                  <Select
                    className={
                      "fullWidth selector-general" +
                      (fieldState.error ? " input-general-error" : "")
                    }
                    options={tariffPlansOptions}
                    {...field}
                  />
                  {fieldState.error ? (
                    <div className="text-error">{fieldState.error.message}</div>
                  ) : null}
                </React.Fragment>
              )}
            />
          </div>
          {showProfit && (
            <div className={"form-box-select"}>
              <Controller
                control={control}
                name="profitSharePercentage"
                rules={{
                  required: "Profit Share % is required",
                }}
                render={({ field, fieldState }) => (
                  <Input
                    className={"form-box-input"}
                    label={"Profit Share %"}
                    placeholder={dictionary.company}
                    {...field}
                    error={fieldState.error?.message}
                  />
                )}
              />
            </div>
          )}
        </div>
        <div className="form-box">
          <h3>Notes</h3>
          <Controller
            control={control}
            name="notes"
            render={({ field }) => (
              <TextArea rows={8} className={"form-box-textarea"} {...field} />
            )}
          />
        </div>
      </div>
      <div className="form-column">
        <div className="form-box">
          <div className={"form-box-select"}>
            <h3>Finances</h3>
            <Controller
              control={control}
              name="label"
              rules={{
                required: "Label is required",
              }}
              render={({ field, fieldState }) => (
                <React.Fragment>
                  <label className="allWidth">
                    Label<span>*</span>
                  </label>
                  <Select
                    className={
                      "fullWidth selector-general" +
                      (fieldState.error ? " input-general-error" : "")
                    }
                    options={labelOptions}
                    {...field}
                  />
                  {fieldState.error ? (
                    <div className="text-error">{fieldState.error.message}</div>
                  ) : null}
                </React.Fragment>
              )}
            />
          </div>
          <Controller
            control={control}
            name="minPayout"
            render={({ field }) => (
              <Input
                className={"form-box-input"}
                label={"Min Payout"}
                placeholder={"Min Payout"}
                type="number"
                min={0}
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="selfBillingInvoices"
            render={({ field }) => (
              <Checkbox {...field} checked={!!field.value}>
                Generate Self Billing Invoice
              </Checkbox>
            )}
          />
          <Controller
            control={control}
            name="feesInvoices"
            render={({ field }) => (
              <Checkbox {...field} checked={!!field.value}>
                Generate Fees Invoice
              </Checkbox>
            )}
          />
          <Controller
            control={control}
            name="withholdingTax"
            render={({ field }) => (
              <Checkbox {...field} checked={!!field.value}>
                Deduct Withholding Tax
              </Checkbox>
            )}
          />
        </div>
        <div className="form-box">
          <h3>Auto Payment</h3>
          <Controller
            control={control}
            name="autoPayment"
            render={({ field }) => (
              <Checkbox {...field}>Generate Auto Payment</Checkbox>
            )}
          />
        </div>
        <div className="form-box">
          <h3>Contract Details</h3>
          <Controller
            control={control}
            name="startDate"
            rules={{
              required: "Start Date is required",
            }}
            render={({ field, fieldState }) => (
              <DateInput
                label={"Start Date"}
                className={fieldState.error ? "input-general-error" : ""}
                {...field}
                required
                error={fieldState.error?.message}
              />
            )}
          />
          <Controller
            control={control}
            name="endDate"
            rules={{
              required: "End Date is required",
            }}
            render={({ field, fieldState }) => (
              <DateInput
                label={"End Date"}
                className={fieldState.error ? "input-general-error" : ""}
                {...field}
                required
                error={fieldState.error?.message}
              />
            )}
          />
          <div className={"form-box-select"}>
            <Controller
              control={control}
              name="categories"
              render={({ field }) => (
                <React.Fragment>
                  <label className="allWidth">Categories</label>
                  <Select
                    mode="multiple"
                    className={"fullWidth selector-general"}
                    options={categoriesContractOptions}
                    {...field}
                  />
                </React.Fragment>
              )}
            />
          </div>
        </div>
        <div className="form-box">
          <h3>Files</h3>
          <p>Please save the contract before uploading files</p>
        </div>
        <div className="form-box">
          <h3>Corss Contract</h3>
          <p>
            You can use this function to add or subtract onto another statement,
            in situations such as summary accounts or producer contracts.
            Configure the terms below to select the contracts and conditions
            that you want to add the output of this contract onto another, and
            the percentage to add onto that other contract.
          </p>
          <p>
            {`Selecting 'Add To' adds the output from this contract onto another (Summary), 
              whilst 'Subtract From' subtracts the output of this contract from the selected (Producer)`}
          </p>
          {drawCrossContracts()}
          <Button type="primary" onClick={() => addCrossContract()}>
            Add Cross Contract
          </Button>
        </div>
        <div className="form-box">
          <h3>Summary Statement</h3>
          <p>
            Use this function when you want to provide a summary statement using
            Add To transfers above, and include all the sales and cost data and
            present as a single statement. The statements will still be
            transferred as transactions, but the main statement PDF and
            statement within the application will total all sales and costs
            associated.
          </p>
          <p>
            Check this on the summary contract, not on the individual contracts.
          </p>
          <Controller
            control={control}
            name="associatedStatements"
            render={({ field }) => (
              <Checkbox {...field} checked={!!field.value}>
                Total all Associated Statements
              </Checkbox>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateContractOverview;
