import React, { useContext } from "react";
import { LanguageContext } from "../../../context/Language";
import ReleaseCard from "./ReleaseCard";

const Releases = ({ releases, changeItem, getReleases }) => {
  const { dictionary } = useContext(LanguageContext);

  return (
    <React.Fragment>
      <ReleaseCard
        changeItem={changeItem}
        releaseList={releases.published || []}
        listName={dictionary.published}
        released={true}
        plus={true}
        section="published"
      />
      {releases.inReview.length !== 0 ? (
        <ReleaseCard
          changeItem={changeItem}
          releaseList={releases.inReview || []}
          listName={dictionary.inReview}
          released={false}
          section="inReview"
        />
      ) : null}
      {releases.readyToSent.length !== 0 ? (
        <ReleaseCard
          changeItem={changeItem}
          releaseList={releases.readyToSent || []}
          listName={dictionary.sent}
          released={false}
          section="readyToSent"
        />
      ) : null}
      <ReleaseCard
        canDelete={true}
        getReleases={getReleases}
        changeItem={changeItem}
        releaseList={releases.draft || []}
        listName={dictionary.draft}
        released={false}
        section="draft"
      />
    </React.Fragment>
  );
};

export default Releases;
