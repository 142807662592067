import { Card, Col, Row, Modal, Input, Select, Button, Typography } from "antd";
import React, { useContext, useState } from "react";
import { AppContext } from "../../../../context/App";
import { LanguageContext } from "../../../../context/Language";
import { CheckCircleFilled } from "@ant-design/icons";
import moment from "moment";
import Loader from "../../../Loader/Loader";
import MarkIcon from "../../../Icon/MarkIcon";
import Admin from "../../../../classes/admin/admin";
import ModalResetPwd from "../../ModalResetPwd/ModalResetPwd";
import GeneralButton from "../../../Buttons/GeneralButton/GeneralButton";

const OtherDetails = (props) => {
  const [enableEdit, setEnableEdit] = useState(false);
  const { dictionary } = useContext(LanguageContext);
  const { affirmationOptions } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState({
    userid: "",
    show: false,
    email: "",
    language: "",
  });
  const { confirm } = Modal;

  const { dispatch, dataToUpdate, isEditMode } = props;

  const showModalResetPassword = (user_id = "", email = "", language = "") => {
    setIsModalVisible({
      userid: user_id,
      show: true,
      email: email,
      language: language,
    });
  };

  const hideModalResetPwd = () => {
    setIsModalVisible({
      userid: "",
      show: false,
      email: "",
      language: "",
    });
  };

  const showConfirm = (newPwd) => {
    confirm({
      className: "pwd-confirm",
      title: dictionary.resetPwdSuccess,
      icon: <CheckCircleFilled className="pwd-confirm-icon" />,
      content: (
        <div>
          {dictionary.resetPwdSuccessDesc}
          <p className="newPwd">
            {dictionary.newPassword}: {newPwd}
          </p>
        </div>
      ),
      okText: dictionary.close,
      onOk() {
        Modal.destroyAll();
      },
    });
  };

  const changePwd = (id, email, lang) => {
    setLoading(true);
    const language = lang.includes("es") ? "es" : "en";
    Admin.userTempPwd({ id, email, language })
      .then((res) => {
        if (res.status === 200) {
          showConfirm(res.body.pwd);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    hideModalResetPwd();
  };

  return (
    <div className="general_information user-detls">
      {loading && <Loader />}
      <Row>
        <Col>
          <Typography.Title level={3}>{dictionary.details}</Typography.Title>
        </Col>
        <Col></Col>
      </Row>
      <Row justify="space-around" className="user-div">
        <Col xs={24} lg={24} className="info-one">
          <Card loading={props.isLoading}>
            {isEditMode ? (
              <Button
                className={"edit_button usr" + (enableEdit ? " active" : "")}
                shape="circle"
                icon={<MarkIcon />}
                onClick={() => setEnableEdit(!enableEdit)}
              />
            ) : null}
            <Row justify="space-between" className="mt-2 mr-4 content-user-det">
              <Col className="label" xs={24} md={24} lg={12}>
                <label>{dictionary.verifiedUser}</label>
                <Select
                  defaultValue={
                    dataToUpdate?.verified ??
                    (props.verified ? dictionary.yes : dictionary.no)
                  }
                  options={affirmationOptions}
                  onChange={(e) => {
                    dispatch({
                      type: "verify",
                      payload: {
                        name: "verified",
                        original: props.verified,
                        value: e,
                      },
                    });
                  }}
                  disabled={!enableEdit}
                />
              </Col>
              <Col className="label" xs={24} md={24} lg={12}>
                <label>{dictionary.email}</label>
                <Input
                  defaultValue={dataToUpdate?.email ?? props?.email}
                  onChange={(e) => {
                    dispatch({
                      type: "simpleChange",
                      payload: {
                        name: "email",
                        original: props?.email,
                        value: e.target.value,
                      },
                    });
                  }}
                  disabled={!enableEdit}
                />
              </Col>
              <Col className="label" xs={24} md={24} lg={12}>
                <label>{dictionary.userType}</label>
                <p>{dictionary[props.user_type]}</p>
              </Col>

              <Col className="label" xs={24} md={24} lg={12}>
                <label>{dictionary.loginMethod}</label>
                <p>{dictionary.methods[props.method]}</p>
              </Col>
              <Col className="label" xs={24} md={24} lg={12}>
                <label>{dictionary.createdAt}</label>
                <p>{moment(props.createdAt).format("YYYY/MMM/DD")}</p>
              </Col>
              <Col className="label" xs={24} md={24} lg={12}>
                <label>{dictionary.updatedAt}</label>
                <p>{moment(props.updatedAt).format("YYYY/MMM/DD")}</p>
              </Col>
              <Col xs={24} md={24} lg={10}>
                <GeneralButton
                  text={dictionary.createNewPassword}
                  onClick={() => showModalResetPassword(props._id, props.email)}
                  disabled={!enableEdit}
                />
              </Col>
            </Row>
          </Card>
        </Col>
        <ModalResetPwd
          className=" "
          changePwd={changePwd}
          isModalVisible={isModalVisible}
          hideModalResetPwd={hideModalResetPwd}
        />
      </Row>
    </div>
  );
};

export default OtherDetails;
