import React, { useContext, useEffect, useState } from "react";
import { notification } from "antd";
import { UserContext } from "../../../context/User";
import { ArtistContext } from "../../../context/Artist";
import { LanguageContext } from "../../../context/Language";
import { createFileObjectState, URLFileName } from "../../../hooks/files/files";
import List from "../List";
import Tables from "../Tables";
import Modal from "../../Modal";
import validator from "validator";
import Loader from "../../Loader";
import AddArtist from "./AddArtist";
import Button from "../../Buttons/GeneralButton";
import Artist from "../../../classes/artist/artist";
import Country from "../../../classes/country/country";

const { isEmail } = validator;

const ArtistList = ({
  labels,
  searchValues,
  filter,
  loadList,
  labelArtist,
}) => {
  const { getArtists, artists, loadingArtists } = useContext(ArtistContext);
  const { dictionary } = useContext(LanguageContext);
  const { permission, type } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [headArtist, setHeadArtist] = useState(false);
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [openData, setOpenData] = useState({
    name: "",
    label_id: type === "artist" ? labelArtist?._id : 0,
    image: "",
    description: "",
    dsp: [],
    email: "",
    password: "",
    confirmPassword: "",
    countryPhone: "US",
    code: "1",
    number: "",
    address: "",
    city: "",
    state: "",
    country: 0,
    zip: "",
    update: false,
    hasUser: false,
  });

  const [deleteData, setDeleteData] = useState({
    name: "",
    hasRelease: 0,
    show: false,
  });

  const columns = [
    {
      title: dictionary.picture,
      dataIndex: "details",
      className: "id-category",
    },
    {
      title: dictionary.name,
      dataIndex: "name",
      className: "title-category",
    },
    {
      title: dictionary.label,
      dataIndex: "desc",
      className: "title-category",
    },
    {
      title: dictionary.featured,
      dataIndex: "featured",
      className: "title-category",
    },
    {
      title: dictionary.createdAt,
      dataIndex: "createdAt",
      className: "title-category",
    },
    {
      title: dictionary.updated,
      dataIndex: "updated",
      className: "title-category",
    },
    {
      title: dictionary.details,
      dataIndex: "detail",
      className: "id-category",
    },
  ];

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [imageUrl, setImage] = useState([]);
  const [urlOriginal, setImgOriginal] = useState([]);
  const [listArtist, setArtistList] = useState([]);
  const [phoneCountries, setPhoneCountries] = useState([]);
  const [dataCountries, setData] = useState([]);
  const [loadingImage, setLoadingImage] = useState(false);
  const [loadArtist, setLoadArtist] = useState(false);
  const [labelList, getLabels] = useState([]);
  const [featuredList] = useState([
    { _id: true, name: dictionary.yes },
    { _id: false, name: dictionary.no },
  ]);

  useEffect(() => {
    setArtistList(artists);
  }, [artists]);

  useEffect(() => {
    if (searchValues) {
      let filteredNames = artists.filter((el) =>
        el.name.toLowerCase().includes(searchValues.toLowerCase())
      );
      setArtistList(filteredNames);
    } else {
      setArtistList(artists);
    }
  }, [searchValues, artists]);

  useEffect(() => {
    if (!filter) return null;
    let artistArray = [...artists];
    switch (filter) {
      case 1:
        artistArray.sort((a, b) => a.name.localeCompare(b.name));
        break;
      case 2:
        artistArray.sort((a, b) => -1 * a.name.localeCompare(b.name));
        break;
      default:
        break;
    }
    setArtistList(artistArray);
  }, [filter, artists]);

  useEffect(() => {
    if (dataCountries.length === 0) getCountries();
  }, [dataCountries.length]);

  useEffect(() => {
    getAllLabels();
    // eslint-disable-next-line
  }, []);

  const getAllLabels = () => {
    const alllabels = artists.map(({ label }) => label);
    const uniqueObjects = [];
    const seenIds = {};
    for (let i = 0; i < alllabels.length; i++) {
      const obj = alllabels[i];
      if (!(obj._id in seenIds)) {
        uniqueObjects.push(obj);
        seenIds[obj._id] = true;
      }
    }
    let uniqueAndOrdered = uniqueObjects.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    getLabels(uniqueAndOrdered);
  };

  const showModal = (artist = null, isHeadArtist = false) => {
    if (artist) {
      setHeadArtist(isHeadArtist);
      if (artist.image) {
        const getResized = artist.image.replace(
          /.jpg|.png|.jpeg|.heic/gi,
          function () {
            return "250x250.webp";
          }
        ); //for artists
        const name = URLFileName(artist.image);
        setImage([
          {
            name: name,
            status: "done",
            url: getResized,
          },
        ]);
        setImgOriginal([
          {
            name: name,
            status: "done",
            url: artist.image,
          },
        ]);
        setImageFile(artist.image);
      } else {
        setImage([]);
        setImgOriginal([]);
      }
      if (artist.user) {
        let profile = artist.user.profile;
        setOpenData({
          ...openData,
          name: artist.name,
          _id: artist._id,
          image: artist.image || null,
          description: artist.description,
          label_id: artist.label_id,
          is_featured: artist.is_featured,
          update: true,
          dsp: artist.dsp || [],
          email: artist.user.email || "",
          countryPhone: profile.phone?.country || "US",
          code: profile.phone?.code || "1",
          number: profile.phone?.number || "",
          address: profile.address?.address || "",
          city: profile.address?.city || "",
          state: profile.address?.state || "",
          country: profile.address?.country || 0,
          zip: profile.address?.zip || "",
          hasUser: true,
        });
      } else {
        setOpenData({
          ...openData,
          name: artist.name,
          _id: artist._id,
          image: artist.image || null,
          description: artist.description,
          label_id: artist.label_id,
          dsp: artist.dsp,
          is_featured: artist.is_featured,
          update: true,
          hasUser: false,
        });
      }
    } else {
      setOpenData({
        name: "",
        label_id: type === "artist" ? labelArtist?._id : 0,
        image: "",
        description: "",
        dsp: [],
        email: "",
        password: "",
        confirmPassword: "",
        countryPhone: "US",
        code: "1",
        number: "",
        address: "",
        city: "",
        state: "",
        country: 0,
        zip: "",
        hasUser: false,
      });
      setImageFile(null);
      setImage([]);
    }
    setErrors({
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      label_id: 0,
      address: "",
      city: "",
      state: "",
      zip: "",
      update: false,
    });
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const changeHandler = (e, name) => {
    const value = e.target.value;
    setOpenData({
      ...openData,
      [name]: value,
    });
  };

  const onChangeCheckbox = (e) => {
    setOpenData({
      ...openData,
      is_featured: e.target.checked,
    });
  };

  const selectChangeHandler = (e, name) => {
    if (name === "code") {
      const getCode = phoneCountries.find((el) => el._id === e);
      setOpenData({
        ...openData,
        countryPhone: getCode._id,
        code: String(getCode.phone_code),
      });
    } else {
      setOpenData({
        ...openData,
        [name]: e,
      });
    }
  };

  const setArtist = async (artist) => {
    const list = listArtist;
    list.push(artist);
    setArtistList(list);
  };

  const handleChange = async ({ file }) => {
    setImageFile(file);
    if (file.status === "uploading") {
      setLoadingImage(true);
      createFileObjectState(file, setImage);
    } else if (file.status === "removed" && imageUrl.length) {
      if (imageUrl[0].uid) {
        if (imageUrl[0].uid.includes("__AUTO__")) {
          await Artist.deleteImageArtist(imageUrl[0].url, openData);
          getArtists();
        }
        setImageFile(null);
        setImage([]);
      }
    }
    setLoadingImage(false);
  };

  const showModalDelete = (index, artist) => {
    setDeleteData({
      index,
      name: artist.name,
      hasRelease: artist.hasRelease,
      show: true,
    });
  };

  const hideModalDelete = () => {
    setDeleteData({
      name: "",
      index: 0,
      hasRelease: 0,
      show: false,
    });
  };

  const removeParticipant = async () => {
    const listCopy = [...listArtist];
    const index = deleteData.index;
    const deleteElement = listCopy[index];
    await Artist.deleteArtist(deleteElement.artist_id || deleteElement._id)
      .then((resp) => {
        if (resp.status === 200) {
          if (!deleteElement.artist_id && deleteElement.image) {
            const url = deleteElement.image;
            Artist.deleteImageArtist(url);
          }
          listCopy.splice(index, 1);
          setArtistList(listCopy);
        }

        if (resp.body.message === "artistHasRelease") {
          notification.error({
            key: "cantDeleteArtistModal",
            placement: "bottomRight",
            duration: 5,
            message: dictionary.unableDeleteArtist,
          });
        }
      })
      .catch((resp) => {
        console.error("Artist deleted error", resp);
      });
    // TODO add a response for fail in the modal before close it
    hideModalDelete();
  };

  const handleSubmitUpdate = async () => {
    setLoadArtist(true);
    let hasError,
      errorAddress,
      hasAddress = false;
    let errorsCopy = {
      name: "",
      address: "",
      city: "",
      state: "",
      zip: "",
    };
    if (!openData.name) {
      hasError = true;
      errorsCopy.name = "emptyField";
    }
    if (!openData._id) {
      hasError = true;
      // the id user not exists
    }

    // if one address field was filed all need to be mandatory
    if (openData.address || openData.city || openData.state || openData.zip) {
      hasAddress = true;
      if (!openData.address) {
        errorAddress = true;
        errorsCopy.address = "emptyField";
      }
      if (!openData.city) {
        errorAddress = true;
        errorsCopy.city = "emptyField";
      }
      if (!openData.state) {
        errorAddress = true;
        errorsCopy.state = "emptyField";
      }
      if (!openData.country) {
        errorAddress = true;
        errorsCopy.country = "selectErr";
      }
      if (!openData.zip) {
        errorAddress = true;
        errorsCopy.zip = "emptyField";
      }
    }
    setErrors(errorsCopy);
    try {
      if (!hasError && !errorAddress) {
        setLoading(true);
        setIsModalVisible(false);
        let newArtist = {
          artist_id: openData._id,
          name: openData.name,
          description: openData.description || openData.name,
          is_featured: false,
          dsp: openData.dsp || [],
        };

        let profile = {};

        if (openData.number) {
          profile.phone = {
            country: openData.countryPhone,
            code: openData.code,
            number: openData.number,
          };
        }
        if (hasAddress) {
          profile.address = {
            address: openData.address,
            city: openData.city,
            state: openData.state,
            country: openData.country,
            zip: openData.zip,
          };
        }
        const updatedArtist = await Artist.update(
          newArtist,
          profile,
          imageFile && imageFile.originFileObj
            ? imageFile.originFileObj
            : imageFile
        );
        console.log(headArtist);
        // Pending next pr, to update the label of artist user
        // if (headArtist) {
        //   let label = {
        //     label_id: labelData._id || labelData.label_id,
        //     name: labelData.name,
        //   };
        //   const updateLabel = await User.updateLabel(label);
        //   console.log(updateLabel);
        // }
        if (updatedArtist) {
          getArtists();
          setLoadArtist(false);
          setIsModalVisible(false);
          notification.success({
            message: dictionary.artistUpdated,
          });
        } else {
          setLoadArtist(false);
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    let hasError,
      errorAddress,
      hasAddress = false;
    let errorsCopy = {
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      label_id: 0,
      address: "",
      city: "",
      state: "",
      zip: "",
    };
    if (!openData.name) {
      hasError = true;
      errorsCopy.name = "emptyField";
    }
    if (!openData.label_id || openData.label_id === 0) {
      hasError = true;
      errorsCopy.label_id = "selectErr";
    }
    if (openData.email) {
      if (!isEmail(openData.email)) {
        hasError = true;
        errorsCopy.email = "mailErr";
      }
      if (!openData.password) {
        hasError = true;
        errorsCopy.password = "emptyField";
      } else if (openData.password.length < 8) {
        hasError = true;
        errorsCopy.password = "passErr";
      }
      if (
        openData.password !== "" &&
        openData.confirmPassword !== openData.password
      ) {
        hasError = true;
        errorsCopy.confirmPassword = "confirmPwdErr";
      }

      // if one address field was filed all need to be mandatory
      if (openData.address || openData.city || openData.state || openData.zip) {
        hasAddress = true;
        if (!openData.address) {
          errorAddress = true;
          errorsCopy.address = "emptyField";
        }
        if (!openData.city) {
          errorAddress = true;
          errorsCopy.city = "emptyField";
        }
        if (!openData.state) {
          errorAddress = true;
          errorsCopy.state = "emptyField";
        }
        if (!openData.country) {
          errorAddress = true;
          errorsCopy.country = "selectErr";
        }
        if (!openData.zip) {
          errorAddress = true;
          errorsCopy.zip = "emptyField";
        }
      }
    }
    setErrors(errorsCopy);
    try {
      if (!hasError && !errorAddress) {
        setIsModalVisible(false);
        const newParticipant = {
          name: openData.name,
        };
        const newArtist = {
          label_id: openData.label_id,
          name: openData.name,
          description: openData.description || openData.name,
          is_featured: false,
          dsp: openData.dsp || [],
        };
        let user = null;
        if (openData.email) {
          user = {
            email: openData.email,
            password: openData.password,
            confirmPassword: openData.confirmPassword,
            profile: {},
          };

          if (openData.number) {
            user.profile.phone = {
              country: openData.countryPhone,
              code: openData.code,
              number: openData.number,
            };
          }
          if (hasAddress) {
            user.profile.address = {
              address: openData.address,
              city: openData.city,
              state: openData.state,
              country: openData.country,
              zip: openData.zip,
            };
          }
        }
        const createdArtist = await Artist.create(
          newArtist,
          user,
          imageFile ? imageFile.originFileObj : null
        );
        if (createdArtist) {
          newParticipant.artist_id = createdArtist._id;
          newParticipant.image = createdArtist.image;
          getArtists();
          await setArtist(newParticipant);
          notification.success({
            message: dictionary.artistSuccess,
          });
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const getCountries = async () => {
    const { body } = await Country.countries();
    setData([...body.countries]);
    const phoneCountries = Country.setPhoneCountries();
    setPhoneCountries([...phoneCountries]);
  };

  return (
    <React.Fragment>
      {loadingArtists || loading ? <Loader /> : null}
      {type === "admin" ? (
        <Tables
          error={false}
          typeTable={1}
          released={true}
          isArtists={true}
          columns={columns}
          subjects={artists}
          loadList={loadList}
          allLabels={labelList}
          showModal={showModal}
          list={listArtist || []}
          remove={showModalDelete}
          title={dictionary.artists}
          setNewList={setArtistList}
          allIsFeature={featuredList}
          canUpdate={permission?.artist?.update || false}
          canDelete={permission?.artist?.delete || false}
        />
      ) : (
        <List
          plus={true}
          error={false}
          released={true}
          showModal={showModal}
          list={listArtist || []}
          remove={showModalDelete}
          title={dictionary.artists}
          emptyText={dictionary.noArtists}
          canAdd={permission?.artist?.add || false}
          canUpdate={permission?.artist?.update || false}
          canDelete={permission?.artist?.delete || false}
        />
      )}
      {permission?.artist?.add || permission?.artist?.update ? (
        <AddArtist
          type={type}
          errors={errors}
          labels={labels}
          artist={openData}
          imageUrl={imageUrl}
          setImage={setImage}
          loading={loadingImage}
          loadArtist={loadArtist}
          headArtist={headArtist}
          urlOriginal={urlOriginal}
          labelArtist={labelArtist}
          handleChange={handleChange}
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          dataCountries={dataCountries}
          changeHandler={changeHandler}
          phoneCountries={phoneCountries}
          isModalVisible={isModalVisible}
          onChangeCheckbox={onChangeCheckbox}
          handleSubmitUpdate={handleSubmitUpdate}
          selectChangeHandler={selectChangeHandler}
        />
      ) : null}
      {permission?.artist?.delete ? (
        <Modal
          idModal={"deleteArtist"}
          handleCancel={handleCancel}
          isModalVisible={deleteData.show}
          title={<div className="modal-title">{dictionary.deleteArtist}</div>}
          body={
            <div>
              {deleteData.hasRelease > 0
                ? dictionary.unableDeleteArtist
                : dictionary.deleteMessageArtistName.replace(
                    "--artist--",
                    deleteData.name
                  )}
            </div>
          }
          footer={
            <div className="modalAdd-btns">
              <Button
                key="cancel"
                size="sm"
                className="back-btn"
                text={dictionary.cancel}
                onClick={hideModalDelete}
              />
              <Button
                key="submit"
                size="md"
                text={dictionary.delete}
                disabled={deleteData.hasRelease > 0}
                className="footer-btn"
                onClick={removeParticipant}
              />
            </div>
          }
        />
      ) : null}
    </React.Fragment>
  );
};

export default ArtistList;
