import { Button, Typography, Popconfirm, Spin, notification } from "antd";
import React, { useContext, useState } from "react";
import { LanguageContext } from "../../../../../context/Language";
import AdminCalls from "../../../../../classes/admin/admin";

const UPCSetter = ({
  releaseId,
  disabled,
  upc,
  loadRelease,
  setShowAddUpcIsrcModal,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { dictionary } = useContext(LanguageContext);

  const assignUPC = () => {
    setIsLoading(true);
    AdminCalls.releaseUPC(releaseId)
      .then((response) => {
        if (!response.error) {
          notification.success({
            key: "releaseDetailNotification",
            placement: "bottomRight",
            message: dictionary.successGeneral,
            duration: 5,
          });
          loadRelease();
        } else {
          if (response.message === "notAvailableUpc")
            notification.error({
              key: "releaseDetailnotAvailableUpcNotification",
              placement: "bottomRight",
              message: dictionary.notAvailableUpcAdd,
              duration: 30,
              onClick: () => setShowAddUpcIsrcModal(true),
            });
          else
            notification.error({
              key: "releaseDetailNotification",
              placement: "bottomRight",
              message: dictionary.errorGeneral,
              duration: 5,
            });
        }
      })
      .catch(() => {
        notification.error({
          key: "releaseDetailNotification",
          placement: "bottomRight",
          message: dictionary.errorGeneral,
          duration: 5,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (isLoading)
    return (
      <Typography.Title className="mb-0" level={4}>
        UPC:&nbsp;
        <Spin />
      </Typography.Title>
    );

  if (upc)
    return (
      <Typography.Title className="mb-0" level={4}>
        UPC:&nbsp;{upc}
      </Typography.Title>
    );

  if (disabled)
    return (
      <Typography.Title className="mb-0" level={4}>
        UPC:&nbsp;
        <Button disabled={disabled} type={"primary"} className="assign-upc">
          {dictionary.assign}
        </Button>
      </Typography.Title>
    );

  return (
    <Typography.Title className="mb-0" level={4}>
      UPC:&nbsp;
      <Popconfirm
        placement="right"
        title={dictionary.upcRequestConfirm}
        onConfirm={assignUPC}
        okText={dictionary.yes}
        cancelText={dictionary.no}
      >
        <Button type={"primary"} className="assign-upc">
          {dictionary.assign}
        </Button>
      </Popconfirm>
    </Typography.Title>
  );
};

export default UPCSetter;
