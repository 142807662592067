import React, {
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { Table, message } from "antd";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { LanguageContext } from "../../../../context/Language";
import {
  ArrowLeftOutlined,
  MenuOutlined,
  PlusOutlined,
  PauseOutlined,
} from "@ant-design/icons";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import TrackClass from "../../../../classes/track/track";
import Release from "../../../../classes/release/release";
import TrackInfo from "./TrackInfo";
import PlayIcon from "../../../Icon/PlayIcon";
import EditIcon from "../../../Icon/EditIcon";
import TrackDelete from "./TrackDelete";
import CrossIcon from "../../../Icon/CrossIcon";
import Button from "../../../Buttons/GeneralButton";
import Loader from "../../../Loader";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
let Track;

const Tracks = ({
  state,
  setInput,
  setValid,
  validation,
  backSection,
  nextSection,
  validateStep,
  changeSection,
  setCanMove,
  newNumber,
  setNewNumber,
  loaderTrack,
  setLoaderTrack,
  trackToUpload,
  setTrackToUpload,
}) => {

  const { dictionary } = useContext(LanguageContext);
  const [tableData, setTableData] = useState([]);
  const [itemTrack, setItemDelete] = useState([]);
  const [exampleData, setExampleData] = useState([]);
  const [addVisible, modalAdd] = useState(false);
  const [deleteVisible, modalDelete] = useState(false);
  const [errors, setError] = useState({
    trackErr: false,
    trackUploading: false,
  });
  const [indexTrackView, setIndexTrackView] = useState(null);
  const [loader, setLoader] = useState(false);
  const [isAddable, setIsAddable] = useState(true);
  const notDraggableOptions = ["In Review", "Delivered", "Published"];
  const audioPlayer = useRef(null);
  const audioSource = useRef(null);

  const columns = [
    {
      title: "Sort",
      dataIndex: "sort",
      width: 30,
      className: "drag-visible",
      render: () => <MenuOutlined style={{ cursor: "grab", color: "#999" }} />,
    },
    {
      title: dictionary.order,
      dataIndex: "order",
      className: "drag-visible",
    },
    {
      title: dictionary.title,
      dataIndex: "title",
    },
    {
      title: dictionary.displayArtist,
      dataIndex: "displayArtist",
    },
    {
      title: dictionary.details,
      dataIndex: "details",
    },
  ];

  useEffect(() => {
    if (!Track 
      || state.tracks.length === 0
      || (state.tracks?.length !== 0 && (state._id !== Track?.tracks[0]?.release_id))) Track = new TrackClass();

    setExampleData(
      [...Track.tracks].length !== 0 ? [...Track.tracks] : state.tracks
    );
    
    setLoaderTrack(
      [...state.tracks].map((el, index) => {
        return { loading: false, index: index };
      })
    );

    setFilesOnInit();
    checkAddable();
  }, [state.tracks.length]);

  useEffect(() => {
      setTracks([...exampleData]);
      Track.insertTrackState(exampleData);
  }, [exampleData.length]);

  useEffect(() => {
    if (trackToUpload.length) {
      trackToUpload.forEach((item, index) => {
        if (item.fileUploaded && item.pending) {
          trackToUpload[index].pending = false;
          setTracks(Track.tracks);
        }
      });
      setTrackToUpload(trackToUpload);
    }
  }, [trackToUpload]);

  const checkAddable = () => {
    switch (state.format) {
      case "Single":
        setIsAddable(state.tracks.length < 3);
        break;
      case "EP":
        setIsAddable(state.tracks.length < 6);
        break;
      case "Album":
        setIsAddable(state.tracks.length < 35);
        break;
      default:
        setIsAddable(true);
        break;
    }
  };

  const setFilesOnInit = () => {
    if (state.tracks.length === 0) return;
    // verify if track has the file track uploaded
    setTrackToUpload(
      [...state.tracks].map((el, index) => {
        const s3Url = el.s3_url || null;
        const file = el.asset?.length && el.asset[0]?.filename;
        const audio = (s3Url && file && s3Url + "/" + file) || null;
        return {
          fileUploaded: trackToUpload[index]?.fileUploaded || false,
          pending: trackToUpload[index]?.pending || false,
          percentageUploaded: trackToUpload[index]?.percentageUploaded || null,
          file: null,
          fileUrl: audio,
          playing: false,
        };
      })
    );
  };

  const playCurrentSong = (index, trackItem) => {
    trackToUpload.map((el, i) => {
      if (index === i) {
        const playing = !trackToUpload[i].playing;
        trackToUpload[i].playing = playing;
        playAudio(i, playing, trackItem);
      } else {
        trackToUpload[i].playing = false;
      }
    });
    setTrackToUpload(trackToUpload);
    setTracks(Track.tracks);
  };

  const playAudio = async (index, playing, trackItem) => {
    let audio = "";
    if (trackToUpload[index].fileUrl.includes("base64")) {
      const s3Url = trackItem?.s3_url || null;
      const file = trackItem?.asset?.length && trackItem?.asset[0]?.filename;
      audio = s3Url && file && s3Url + "/" + file;
    } else {
      audio = trackToUpload[index].fileUrl;
    }
    if (audioSource.current.src !== audio) {
      audioPlayer.current.pause();
      audioSource.current.src = audio;
      await audioPlayer.current.load();
    }
    if (playing) {
      try {
        await audioPlayer.current.play();
      } catch (error) {
        console.error("Audio play failed:", error);
      }
    } else {
      audioPlayer.current.pause();
    }
  };

  useEffect(() => {
    validateStep(next);
    // eslint-disable-next-line
  }, [state.tracks]);

  const playButton = (trackToUpload) => {
    return trackToUpload.playing ? (
      <PauseOutlined />
    ) : (
      <PlayIcon />
    );
  };

  const orderedList = (list = [], roles = false) => {
    const count = list.length;
    if (Array.isArray(list)) {
      return list.map((el, index) => {
        if (count === index + 1 && count > 1) {
          return (
            " " +
            dictionary.and +
            " " +
            (roles ? dictionary.artistType[el] : el.name)
          );
        } else if (count - 2 === index && count > 1) {
          return roles ? dictionary.artistType[el] : el.name;
        } else if (count < 3) {
          return (roles ? dictionary.artistType[el] : el.name) + " ";
        } else {
          return (roles ? dictionary.artistType[el] : el.name) + ", ";
        }
      });
    }
  };

  const artistsPreview = (artists) => {
    const onlyMain = artists.filter((el) => el.role.includes("main"));
    const onlyFeat = artists.filter((el) => el.role.includes("feature"));
    const countFeat = onlyFeat.length;

    let setMains = orderedList(onlyMain);

    let setFeat = onlyFeat.map((el, index) => {
      if (countFeat === index + 1 && countFeat > 1) {
        return " " + dictionary.and + " " + el.name;
      } else if (countFeat < 3) {
        return dictionary.featuring + el.name + " ";
      } else if (index === 0) {
        return dictionary.featuring + el.name + ", ";
      } else {
        return el.name + ", ";
      }
    });
    setMains.push(setFeat);
    return setMains;
  };

  /**
   * Update the table data for re-render
   * @param {Array<TrackClass>} arrayTracks
   * @return void
   */
  const setTracks = (arrayTracks) => {
    let trackTable = [];
    for (const trackItem of arrayTracks) {
      const index = arrayTracks.indexOf(trackItem);
      const artist = artistsPreview(trackItem.artists);
      const findItem = loaderTrack.findIndex((el) => el.index === index)
      const track = trackToUpload[index];
      trackTable.push({
        key: trackItem.id || index,
        dataIndex: trackItem._id,
        order: trackItem.number,
        title: trackItem.title || trackItem.name,
        displayArtist: artist,
        details: (
          <div className="track-details">
            {loaderTrack[findItem].loading ? null : (
              <div onClick={() => playCurrentSong(index, trackItem)}>
                {playButton(track)}
              </div>
            )}
            {(loaderTrack[findItem].loading) ||
            notDraggableOptions.includes(state.audiosalad_status) ? null : (
              <EditIcon onClick={() => showAdd(index)} />
            )}
            {(loaderTrack[findItem].loading) ||
            notDraggableOptions.includes(state.audiosalad_status) ? null : (
              <CrossIcon onClick={() => showDelete(trackItem)} />
            )}
            {loaderTrack[findItem].loading ? <Spin indicator={antIcon} /> : null}
          </div>
        ),
        index: index,
      });
    }
    setTableData(trackTable);
  };

  const moveRow = useCallback(
    async (dragIndex, hoverIndex) => {
      const uploadOrder = [];
      const newTracksToUpload = [];
      const newExampleData = [];
      const backData = [...exampleData];
      setExampleData([]);
      setTrackToUpload([]);
      for (let i = 0; i < tableData.length; i++) {
        let newOrder = i;
        let diff = Math.abs(hoverIndex - dragIndex);
        if (dragIndex < hoverIndex) {
          newOrder =
            i >= dragIndex && i < hoverIndex
              ? i + 1
              : i === hoverIndex
              ? i - diff
              : i;
        } else if (dragIndex > hoverIndex) {
          newOrder =
            i < hoverIndex
              ? i
              : i === hoverIndex
              ? i + diff
              : i > hoverIndex && i <= dragIndex
              ? i - 1
              : i;
        }

        backData[newOrder].number = i + 1;
        newExampleData.push(backData[newOrder]);
        newTracksToUpload.push(trackToUpload[newOrder]);
        uploadOrder.push({ id: backData[newOrder]._id, order: i + 1 });
      }
      Track.tracks = [...newExampleData];
      setTrackToUpload([...newTracksToUpload]);
      setInput({
        ...state,
        tracks: Track.tracks,
      });
      setExampleData([].concat([...newExampleData]));
      setTracks(Track.tracks);

      Release.setReleaseTrackOrder(state._id, uploadOrder);
    },
    // eslint-disable-next-line
    [tableData]
  );

  const DraggableBodyRow = ({
    index,
    moveRow,
    className,
    style,
    ...restProps
  }) => {
    const type = "DraggableBodyRow";
    const ref = useRef();
    const [, drop] = useDrop({
      accept: type,
      collect: (monitor) => {
        const { index: dragIndex } = monitor.getItem() || {};
        if (dragIndex === index) return {};

        return {
          isOver: monitor.isOver(),
          dropClassName:
            dragIndex < index ? " drop-over-downward" : " drop-over-upward",
        };
      },
      drop: (item) => {
        moveRow(item.index, index);
      },
    });

    const [, drag] = useDrag({
      type,
      item: { index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });
    drop(drag(ref));

    return (
      <tr
        ref={ref}
        className={`${className}`}
        style={{ cursor: "move", ...style }}
        {...restProps}
      />
    );
  };

  const success = () => {
    message.success(dictionary.deletedTrack);
  };

  const handleCancel = () => {
    modalAdd(false);
    modalDelete(false);
  };

  const showDelete = (e) => {
    modalDelete(true);
    setItemDelete(e);
  };

  const showAdd = (index = null) => {
    let errors = {
      trackErr: false,
      trackUploading: false,
    };
    setError(errors);
    setIndexTrackView(index);
    if (index !== null) Track.trackBeforeEdit = { ...exampleData[index] };
    modalAdd(true);
  };

  const deleteTrack = () => {
    setNewNumber(newNumber - 1);
    // find index of track to delete from exampleData
    const removeIndex = exampleData.findIndex(
      (item) => item._id === itemTrack._id
    );
    // delete track from exampleData
    if (exampleData[removeIndex]?.asset?.length) {
      // delete file track added to bucket
      exampleData[removeIndex].asset.forEach((item) => {
        Release.deleteFile(state._id, item.filename);
      });
    }
    const tracktoDelete = exampleData[removeIndex]._id;
    Track.deleteTrackFromState(removeIndex);
    Track.reOrderTracks();
    //setTracks(Track.tracks);
    setExampleData([...Track.tracks]);
    success();
    modalDelete(false);
    Release.removeTracksRelease(state._id, tracktoDelete);
    const currentTracksToUpload = [...trackToUpload];
    currentTracksToUpload.splice(removeIndex, 1);
    setTrackToUpload(currentTracksToUpload);
    setInput({
      ...state,
      tracks: Track.tracks,
    });
    if (Track.tracks.length === 0) {
      setValid({
        ...validation,
        step4: false,
        step5: false,
        step6: false,
      });
    }
  };

  const addTrack = async (trackInfo, file, fileUrl) => {
    let currentIndex = indexTrackView;
    try {
      setCanMove(false);
      setNewNumber(newNumber + 1);
      if (currentIndex === null) {
        currentIndex = Track.tracks.length;
        Track.tracks.push(trackInfo);
        setExampleData([...Track.tracks]);
        if (file) {
          trackToUpload.push({
            fileUploaded: false,
            pending: true,
            percentageUploaded: null,
            file: file,
            fileUrl: fileUrl,
            playing: false,
          });
          setTrackToUpload(trackToUpload);
        }
      } else {
        Track.tracks[currentIndex] = trackInfo;
      }
      let uploaded = true;
      let findIndex = loaderTrack.findIndex((el) => el.index === currentIndex);
      if (file) {
        if (findIndex === -1) {
          findIndex = loaderTrack.length;
          loaderTrack.push({ loading: true, index: currentIndex });
        } else {
          loaderTrack[findIndex].loading = true;
        }
        setLoaderTrack(loaderTrack);
        uploaded = false;
        const track = trackToUpload[currentIndex];
        if (track && track.file) {
          track.percentageUploaded = 0;
          await Release.uploadTrack(track.file.originFileObj, state._id, {
            index: currentIndex,
            filesList: trackToUpload,
          })
            .then(async (resp) => {
              if (resp) {
                uploaded = true;
                trackToUpload[currentIndex].fileUploaded = resp.fileUploaded;
                Track.setFileS3Url(currentIndex, resp.fileS3Url, resp);
                loaderTrack[findIndex].loading = false;
                setLoaderTrack(loaderTrack);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
      if (uploaded) {
        // TODO remove participants from flow, only needed in front
        const trackInfoToUpload = Track.tracks[currentIndex];
        delete trackInfoToUpload.participants;
        const newTrack = await Release.setTracksRelease(
          state._id,
          trackInfoToUpload
        );
        Track.tracks[currentIndex]._id = newTrack;
        // send trackToUpload to parent component because it's not updated in time
        //setTracks(Track.tracks, trackToUploadCopy);
        setExampleData([...Track.tracks]);
        setInput({
          ...state,
          tracks: Track.tracks,
        });
        setCanMove(true);
      } else {
        setLoaderTrack(loaderTrack);
        setTracks(Track.tracks);
      }
      setTrackToUpload(trackToUpload);
    } catch (error) {
      setTrackToUpload(trackToUpload);
      console.log(error);
    }
    
  };

  const validate = () => {
    let error = false;
    let errors = {
      trackErr: false,
      trackUploading: false,
    };
    switch (state.format) {
      case "Single":
        if (state.tracks.length === 0 || state.tracks.length > 3) {
          errors.trackErr = dictionary.trackSingleErr;
          error = true;
        }
        break;
      case "EP":
        if (state.tracks.length < 4 || state.tracks.length > 6) {
          errors.trackErr = dictionary.trackEPErr;
          error = true;
        }
        break;
      case "Album":
        if (state.tracks.length < 7 || state.tracks.length > 35) {
          errors.trackErr = dictionary.trackAlbumErr;
          error = true;
        }
        break;
      default:
        break;
    }
    const isUploading = trackToUpload.find((el) => {
      return !el.fileUploaded && (!el.error || el.pending);
    });
    if (isUploading) {
      errors.trackUploading = dictionary.trackErrUploading;
      error = true;
    }
    if (error) {
      setError({
        ...errors,
        errors,
      });
    }
    return error;
  };

  const back = () => {
    changeSection(3);
    backSection(4);
    window.scrollTo(0, 0);
  };

  const next = (n = null) => {
    const data = validate();
    if (!data) {
      setLoader(true);
      setValid({
        ...validation,
        step4: true,
      });
      if (n) {
        changeSection(n);
        nextSection(n);
      }
      window.scrollTo(0, 0);
      setLoader(false);
    }
  };

  const canMove = () => {
    // verify if all tracks are uploaded
    if (trackToUpload.length === 0) return true;
    // si todos los tracks estan cargados y no hay errores
    return trackToUpload.find((el) => {
      return !el.fileUploaded && (!el.error || el.pending);
    });
  };

  return (
    <div>
      <div className="forms tracks">
        <DndProvider backend={HTML5Backend}>
          <Table
            rowKey="index"
            columns={columns}
            pagination={false}
            dataSource={tableData}
            className="table-tracks"
            components={{
              body: {
                row: !notDraggableOptions.includes(state.audiosalad_status)
                  ? DraggableBodyRow
                  : null,
              },
            }}
            onRow={(record, index) => ({
              index,
              moveRow,
            })}
          />
        </DndProvider>
        {isAddable && !notDraggableOptions.includes(state.audiosalad_status) ? (
          <div className="add-track" onClick={() => showAdd()}>
            <PlusOutlined />
          </div>
        ) : null}
        <audio ref={audioPlayer}>
          <source id="audioSource" src="" ref={audioSource} />
          Your browser does not support the audio format.
        </audio>
        {loader ? <Loader /> : null}
      </div>
      {errors.trackErr ? (
        <div className="error-field">{errors.trackErr}</div>
      ) : null}
      {errors.trackUploading ? (
        <div className="error-field">{dictionary.trackErrUploading}</div>
      ) : null}
      <div className="align-right reg-btn">
        <Button
          disabled={canMove()}
          size="md"
          onClick={back}
          className="back-btn"
          text={dictionary.back}
          icon={<ArrowLeftOutlined />}
        />
        <Button
          disabled={canMove()}
          size="md"
          onClick={() => next(5)}
          text={dictionary.next}
        />
      </div>
      <TrackDelete
        trackName={itemTrack}
        deleteTrack={deleteTrack}
        handleCancel={handleCancel}
        deleteVisible={deleteVisible}
      />
      <TrackInfo
        state={state}
        setInput={setInput}
        addTrack={addTrack}
        index={indexTrackView}
        addVisible={addVisible}
        handleCancel={handleCancel}
        trackToUpload={trackToUpload}
        setOpen={modalAdd}
        dummyData={Track?.track}
        newNumber={newNumber}
      />
    </div>
  );
};

export default Tracks;
