import { useState, useContext } from "react";
import { LanguageContext } from "../../context/Language";

const isValid = (stateData, rules, dictionary) => {
  let errorMessages = {};
  const stateKeys = Object.keys(stateData);
  const ruleKeys = Object.keys(rules);

  const undeclaredKeys = stateKeys.filter((key) => !ruleKeys.includes(key));
  if (undeclaredKeys.length > 0)
    for (const uk of undeclaredKeys)
      errorMessages = {
        ...addError({
          errorMessages,
          key: uk,
          message: `${uk} is not declared in rules`,
        }),
      };

  for (const rk of ruleKeys) {
    const required = rules[rk]?.required;
    const type = rules[rk]?.type;
    const value = stateData[rk];
    const existOnState = Object.hasOwnProperty.call(stateData, rk);

    if (existOnState)
      switch (type) {
        case "string":
          errorMessages = {
            ...addError({
              errorMessages,
              key: rk,
              message: validateString({ value, rules: rules[rk], dictionary}),
            }),
          };
          break;

        case "number":
          errorMessages = {
            ...addError({
              errorMessages,
              key: rk,
              message: validateNumber({ value, rules: rules[rk], dictionary}),
            }),
          };
          break;

        case "array":
          errorMessages = {
            ...addError({
              errorMessages,
              key: rk,
              message: validateArray({ value, rules: rules[rk], key: rk, dictionary}),
            }),
          };
          break;

        default:
          if (type !== "omit")
            console.error(`Type ${type} is not supported, ${rk} key in rules`);
          break;
      } // end of switch

    if (required && !existOnState)
      errorMessages = {
        ...addError({
          errorMessages,
          key: rk,
          message: `${rk} is required`,
        }),
      };
  } // end of for loop

  return errorMessages;
};

/**
 * update the errorMessages object with proper push or set
 * @param {object} errorMessages
 * @param {string | string[]} key
 * @param {message} message
 * @returns {object} - errorMessages object
 */
const addError = ({ errorMessages, key, message }) => {
  if (message.length === 0) return errorMessages;

  if (Object.hasOwnProperty.call(errorMessages, key))
    errorMessages[key] = errorMessages[key].concat(message);
  else errorMessages[key] = Array.isArray(message) ? message : [message];
  return errorMessages;
};

/**
 * @param {string} value - evaluated value
 * @param {object} rules - rules to validate against { min, max, length}
 * @param {string} key - evaluated key
 * @returns {array} - array of error messages
 */
const validateString = ({ value, rules, dictionary }) => {
  const errors = [];
  const { min, max, length, isEmail } = rules;
  // if (!required && value === "") return errors;

  if (length && value.length !== length)
    errors.push(`${dictionary.shouldBe} ${length} ${dictionary.chars}`);
  else if (min && value.length < min)
    errors.push(`${dictionary.shouldBeAtLeast} ${min} ${dictionary.chars}`);
  else if (max && value.length > max)
    errors.push(`${dictionary.shouldBeAtMost} ${max} ${dictionary.chars}`);
  else if (isEmail && !value.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/))
    errors.push(`${dictionary.isNotValidEmailAddress}`);

  return errors;
};

/**
 *
 * @param {*} param0
 * @returns {array} - array of error messages
 */
const validateNumber = ({ value, rules, dictionary }) => {
  const { min, max } = rules;
  const errors = [];
  // if (!required && (value === "" || value === 0)) return errors;

  if (typeof value === "string") value = Number(value.replace(/,/g, ""));
  if (min && value < min) errors.push(`${dictionary.shouldBeAtLeast} ${min} ${dictionary.numbers}`);
  if (max && value > max) errors.push(`${dictionary.shouldBeAtMost} ${max} ${dictionary.numbers}`);
  return errors;
};

const validateArray = ({ value, rules, key, dictionary }) => {

  const errors = [];
  const { item_rules, min, max } = rules;

  // if (!required && value.length === 0) return errors;

  if (min && value.length < min)
    return [`${dictionary.shouldContainAtLeast} ${min} ${dictionary.items}`];

  if (max && value.length > max)
    return [`${dictionary.shouldContainAtMost} ${max} ${dictionary.items}`];

  let i = 0;
  for (const item_value of value) {
    const itemKey = key + "[" + i + "]";
    const itemErr = isValid(
      { [itemKey]: item_value },
      { [itemKey]: item_rules }
    );
    const iek = Object.keys(itemErr);
    if (iek.length) errors.push(`${itemErr[itemKey].join("")}`);

    i++;
  }

  return errors;
};

/**
 *
 * @param {object} rules
 * @example
 * #First create a object that contains the rules for each key in the object
 * const rules = {
 *  [key]: {
 *   type: string | array | number | omit | object
 *   required: bool,
 *   min: number,
 *   max: number,
 *   isEmail: bool,
 *   length: number
 *   item: object
 *  },
 *  ...rules
 * };
 * #Call the hook and pass the rules object
 * const { valid, validationErrorMessages, validate } = useStateValidator(rules);
 * #Call the validate function with the object (fn or effect) that you want to validate
 * const save = () => {
 * validate(componentState);
 * const errors = validate(componentState);
 * };
 */
// Maybe we can pass the onError function to the hook
const useStateValidator = ({ rules }) => {
  const { dictionary } = useContext(LanguageContext);
  const [validationErrorMessages, setValidationErrorMessages] = useState({});

  // In the exported function only pass the data object an use the intilizaed rules
  const validate = (d) => {
    const validationErrors = isValid(d, rules, dictionary);
    setValidationErrorMessages(validationErrors);
    return validationErrors;
  };

  return {
    valid: Object.keys(validationErrorMessages).length === 0,
    validationErrorMessages,
    validate,
  };
};

export default useStateValidator;
