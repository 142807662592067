import React, { useEffect, useContext, useState } from "react";
import {
  Table,
  notification,
  DatePicker,
  Popconfirm,
  Dropdown,
  Spin,
} from "antd";
import { UserContext } from "../../../context/User";
import { LanguageContext } from "../../../context/Language";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import moment from "moment";
import Bluebird from "bluebird";
import Avatar from "react-avatar";
import Select from "../../Form/Select";
import Search from "../../Form/Search";
import AlbumIcon from "../../Icon/AlbumIcon/AlbumIcon.svg";
import SearchIcon from "../../Icon/SearchIcon";
import Button from "../../Buttons/GeneralButton";
import Admin from "../../../classes/admin/admin.jsx";
import Release from "../../../classes/release/release";
import ReleaseElement from "../../Release/Create/Release/ReleaseElement";
import "./style.css";

const Tables = ({
  list,
  error,
  remove,
  columns,
  subjects,
  canUpdate,
  canDelete,
  typeTable,
  showModal,
  setNewList,
  getReleases,
  title,
  isLabel,
  isArtists,
  isRelease,
  getAllReleases,
  loadList,
  allFormats,
  allStatus,
  allLabels,
  allIsFeature,
  orderEmail,
  distro,
  setLoadingList,
  allReleasesAdmin,
  showRejectNoteModal = () => {},
  isAdminRels,
  isUserRel,
  route,
  redirect,
  showDeleteDraft,
  statusTag,
  section = "",
}) => {
  const { RangePicker } = DatePicker;
  const { dictionary } = useContext(LanguageContext);
  const { permission, type } = useContext(UserContext);
  const [search, getSearch] = useState(null);
  const [errorMsg, setErrMsg] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [statusRelease, setStatus] = useState(null);
  const [releaseData, setReleaseData] = useState({ _id: null });
  const [dataSource, setTableData] = useState([]);
  const [originalTable, setOriginalTable] = useState([...subjects]);
  const [initialized, setIinitialized] = useState(false);
  // const [isModalVisible, showModalRelease] = useState(false);
  const [orderTable, setOrder] = useState();
  const [filter, getFilter] = useState(0);
  const [filters, filterOptions] = useState([]);
  const [filterTitle, getFilterTitle] = useState(0);
  const [filterLabel, getFilterLabel] = useState(0);
  const [filterFormat, getFilterFormat] = useState(0);
  const [filterStatus, getFilterStatus] = useState(0);
  const [filterCreated, getFilterCreated] = useState([]);
  const [filterUpdated, getFilterUpdated] = useState([]);
  const [filterFeatured, getFilterFeatured] = useState(0);
  const [filterEmail, getOrderEmail] = useState(0);
  const [statusSection] = useState(window.location.hash.substring(1) || null);
  const [orderAlph] = useState([
    { _id: "A-Z", name: "A-Z" },
    { _id: "Z-A", name: "Z-A" },
  ]);

  useEffect(() => {
    if (statusSection) {
      statusSelect(statusSection);
      getList();
    }
    // eslint-disable-next-line
  }, [statusSection, originalTable]);

  useEffect(() => {
    getList();
    getFilters();
    // eslint-disable-next-line
  }, [list]);

  useEffect(() => {
    if (!initialized && subjects.length) {
      setOriginalTable(subjects);
      setIinitialized(true);
    }
    // eslint-disable-next-line
  }, [subjects.length]);

  /*
  const getReleaseResume = async (id) => {
    const data = await Release.getReleaseResume(id);
    if (!data.error && data.release) {
      setReleaseData(data.release);
      setStatus(data.release.audiosalad_status);
    }
  };
  */

  useEffect(() => {
    if (releaseData._id) {
      mainDisplayData();
    }
    // eslint-disable-next-line
  }, [releaseData._id]);

  useEffect(() => {
    if (errorMsg !== null) {
      notification.error({
        message: errorMsg,
        duration: 15,
        placement: "bottomRight",
      });
    }
  }, [errorMsg]);

  const mainDisplayData = async () => {
    const genres = await getDisplayGenres(releaseData.genres);
    const artist = await artistsPreview(releaseData.artists);
    const tracks = await getDisplayTracks(releaseData.tracks);
    setReleaseData({
      ...releaseData,
      displayArtist: artist,
      displayGenres: genres,
      displayTracks: tracks,
    });
  };

  const getFilters = () => {
    const list = [];
    for (let i = 2; i < columns.length; i++) {
      let nameCol = columns[i].dataIndex;
      if (nameCol !== "detail" && nameCol !== "details") {
        if (
          isRelease &&
          nameCol !== "requestedBy" &&
          nameCol !== "actions" &&
          nameCol !== "priority" &&
          nameCol !== "storesRequested" &&
          nameCol !== "releaseDate"
        ) {
          list.push({ _id: nameCol, name: columns[i].title });
        }
      }
    }
    filterOptions(list);
  };

  const saveOrderAZ = (names, newOrder = null) => {
    const order = newOrder !== null ? newOrder : orderTable;
    const sortered = [...names].sort(function (a, b) {
      const x = a.title || a.name || "";
      const y = b.title || b.name || "";
      if (order === "A-Z") {
        if (x && y) return x.localeCompare(y);
      } else if (order === "Z-A") {
        if (x && y) return y.localeCompare(x);
      } else {
        return b.createdAt.localeCompare(a.createdAt);
      }
    });
    return sortered;
  };

  const emailSelect = (e, dataTable = null) => {
    getOrderEmail(e);
    let finalFilter = [];
    if (!dataTable) {
      const saveOrder = saveOrderAZ(originalTable);
      let saveCreated = saveSection(saveOrder, filterCreated, null, true);
      let saveUpdated = saveSection(
        saveCreated,
        filterUpdated,
        null,
        true,
        false,
        true
      );
      finalFilter = [...saveUpdated];
    } else {
      finalFilter = [...dataTable];
    }
    if (e === "A-Z") {
      finalFilter.sort((a, b) => a.email.localeCompare(b.email));
    } else if (e === "Z-A") {
      finalFilter.sort((a, b) => b.email.localeCompare(a.email));
    } else {
      finalFilter.sort((a, b) => b.updatedAt.localeCompare(a.updatedAt));
    }
    if (dataTable) {
      return finalFilter;
    } else {
      setNewList(finalFilter);
    }
  };

  const saveSection = (
    data,
    filterVar = 0,
    attr,
    isDate = false,
    isBoolean = false,
    isUpdate = false
  ) => {
    let dataNames = [...data];
    if (isDate) {
      if (filterVar.length !== 0) {
        dataNames = data.filter((el) => {
          const itemDate = isUpdate
            ? moment.utc(el.updatedAt).startOf("day")
            : moment.utc(el.createdAt).startOf("day");
          return (
            itemDate >= moment.utc(filterVar[0]).startOf("day") &&
            itemDate <= moment.utc(filterVar[1]).startOf("day")
          );
        });
      }
    } else if (isBoolean) {
      if (filterVar !== 0) {
        dataNames = filterVar
          ? data.filter((el) => el[attr])
          : data.filter((el) => !el[attr]);
      }
    } else {
      if (filterVar !== 0) {
        dataNames = data.filter((el) =>
          el[attr].toLowerCase().includes(filterVar.toLowerCase())
        );
      }
    }
    return dataNames;
  };

  const alphabeticalSelect = async (e) => {
    setOrder(e);
    getFilterTitle(e);
    const saveOrder = await saveOrderAZ(list, e);
    setNewList(saveOrder);
  };

  const formatSelect = (e) => {
    getFilterFormat(e);
    const saveOrder = saveOrderAZ(originalTable);
    let saveStatus = saveSection(saveOrder, filterStatus, "step");
    let saveDates = saveSection(saveStatus, filterCreated, null, true);
    let finalFilter = [...saveDates];
    if (e !== 0) {
      finalFilter = saveDates.filter((el) =>
        el.type.toLowerCase().includes(e.toLowerCase())
      );
    }
    setNewList(finalFilter);
  };

  const statusSelect = (e) => {
    getFilterStatus(e);
    const saveOrder = saveOrderAZ(originalTable);
    let saveFormat = saveSection(saveOrder, filterFormat, "type");
    let saveDates = saveSection(saveFormat, filterCreated, null, true);
    let finalFilter = [...saveDates];
    if (e !== 0) {
      if (e === "inReview" && type !== "admin") {
        finalFilter = saveDates.filter((el) => {
          const step = el.step.toLowerCase(); // Agrega esta línea para ver el valor de step
          return step.includes("inreview") || step.includes("readytosent");
        });
      } else {
        finalFilter = saveDates.filter((el) =>
          el.step.toLowerCase().includes(e.toLowerCase())
        );
      }
      setNewList(finalFilter);
    } else {
      setNewList(originalTable);
    }
  };

  const labelSelect = (e) => {
    getFilterLabel(e);
    const saveOrder = saveOrderAZ(originalTable);
    let saveFeat = saveSection(
      saveOrder,
      filterFeatured,
      "is_featured",
      false,
      true
    );
    let saveCreated = saveSection(saveFeat, filterCreated, null, true);
    let saveUpdated = saveSection(
      saveCreated,
      filterUpdated,
      null,
      true,
      false,
      true
    );
    let finalFilter = [...saveUpdated];
    if (e !== 0) {
      finalFilter = saveUpdated.filter((el) => el.label_id.includes(e));
    }
    setNewList(finalFilter);
  };

  const featureSelect = (e) => {
    getFilterFeatured(e);
    const saveOrder = saveOrderAZ(originalTable);
    let saveLabel = saveSection(saveOrder, filterLabel, "label_id");
    let saveCreated = saveSection(saveLabel, filterCreated, null, true);
    let saveUpdated = saveSection(
      saveCreated,
      filterUpdated,
      null,
      true,
      false,
      true
    );
    let finalFilter = [...saveUpdated];
    if (e !== 0) {
      finalFilter = e
        ? saveUpdated.filter((el) => el.is_featured)
        : saveUpdated.filter((el) => !el.is_featured);
    }
    setNewList(finalFilter);
  };

  const datesSelect = (date, isCreated = false, attr) => {
    const saveOrder = saveOrderAZ(originalTable);
    let saveFormat = isRelease
      ? saveSection(saveOrder, filterFormat, "type")
      : saveOrder;
    let saveStatus = isRelease
      ? saveSection(saveFormat, filterStatus, "step")
      : saveFormat;
    let saveLabel = isArtists
      ? saveSection(saveStatus, filterLabel, "label_id")
      : saveStatus;
    let saveFeat = isArtists
      ? saveSection(saveLabel, filterFeatured, "is_featured", false, true)
      : saveLabel;
    let savedOtherDate = !isCreated
      ? saveSection(saveFeat, filterCreated, null, true)
      : !isRelease
      ? saveSection(saveFeat, filterUpdated, null, true, false, true)
      : saveFeat;
    let saveEmailOrder = isLabel
      ? emailSelect(filterEmail, savedOtherDate)
      : savedOtherDate;
    let filteredDates = [...saveEmailOrder];
    if (date) {
      let getDates = [
        moment.utc(date[0]).startOf("day"),
        moment.utc(date[1]).startOf("day"),
      ];
      if (isCreated) {
        getFilterCreated(getDates);
      } else {
        getFilterUpdated(getDates);
      }
      filteredDates = saveEmailOrder.filter((el) => {
        const itemDate = moment.utc(el[attr]).startOf("day");
        return (
          itemDate >= moment.utc(date[0]).startOf("day") &&
          itemDate <= moment.utc(date[1]).startOf("day")
        );
      });
    } else {
      if (isCreated) {
        getFilterCreated([]);
      } else {
        getFilterUpdated([]);
      }
    }
    setNewList(filteredDates);
  };

  const filtersBar = () => {
    return filters.map((el) => {
      let setOptions = filters;
      let setFunction = (e) => handleSelect(e);
      let setValue = filter;

      switch (el._id) {
        case "name":
          setOptions = orderAlph;
          setFunction = (e) => alphabeticalSelect(e, el);
          setValue = filterTitle;
          break;
        case "title":
          setOptions = orderAlph;
          setFunction = (e) => alphabeticalSelect(e, el);
          setValue = filterTitle;
          break;
        case "format":
          setOptions = allFormats;
          setFunction = (e) => formatSelect(e);
          setValue = filterFormat;
          break;
        case "estatus":
          setOptions = allStatus;
          setFunction = (e) => statusSelect(e);
          setValue = filterStatus;
          break;
        case "desc":
          setOptions = isArtists ? allLabels : orderEmail;
          setFunction = isArtists
            ? (e) => labelSelect(e)
            : (e) => emailSelect(e);
          setValue = isArtists ? filterLabel : filterEmail;
          break;
        case "featured":
          setOptions = allIsFeature;
          setFunction = (e) => featureSelect(e);
          setValue = filterFeatured;
          break;
        default:
          break;
      }
      if (el._id === "createdAt" || el._id === "updated") {
        let isCreate = el._id === "createdAt" ? true : false;
        let attr = el._id === "createdAt" ? el._id : "updatedAt";
        return (
          <div key={el._id} className="dateField">
            <div className="label">{dictionary[el._id]}</div>
            <RangePicker
              defaultValue={
                el._id === "createdAt" ? filterCreated : filterUpdated
              }
              onChange={(e) => datesSelect(e, isCreate, attr)}
              className="date-range-admn"
            />
          </div>
        );
      } else {
        return (
          <Select
            key={el._id}
            name="section"
            value={setValue}
            options={setOptions}
            placeholder={el.name}
            onChange={setFunction}
            className="selector-general search-sec"
          />
        );
      }
    });
  };

  const previewImg = (e, el, originalImg) => {
    try {
      let imgAdmin = document.getElementById("imgAdm" + el);
      if (imgAdmin) {
        imgAdmin.src = originalImg;
        let image = document.createElement("img");
        image.src = originalImg;
        image.addEventListener(
          "error",
          () => {
            var isLoaded = image.complete && image.naturalHeight !== 0;
            if (!isLoaded) {
              imgAdmin.src = AlbumIcon;
            }
          },
          false
        );
      }
    } catch (error) {
      console.log(error);
    }
    return;
  };

  const showStores = (stores) => {
    let all = [];
    for (var i = 0; i < stores.length; i++) {
      all.push(<li key={i}>{stores[i]}</li>);
    }
    return <ul className="view-stores">{all}</ul>;
  };

  const getDspList = (relDsps) => {
    let namesList = [];
    relDsps.forEach((el) => {
      namesList.push(el.name);
    });
    namesList.sort((a, b) => a.localeCompare(b));
    let order = orderedDsps(namesList);
    return order;
  };

  const orderedDsps = (list = []) => {
    let openList = showStores(list);
    if (Array.isArray(list)) {
      return list.map((el, index) => {
        if (index <= 3) {
          if (index === 3 && list.length >= 4) {
            return (
              <Popconfirm
                key={index}
                title={openList}
                placement="bottom"
                okText={dictionary.close}
                icon={null}
                showCancel={false}
              >
                <div className="seemore-btn">{dictionary.seeMore}</div>
              </Popconfirm>
            );
          } else if (
            (index > 0 && list.length < 3) ||
            list.length === 1 ||
            (index === 2 && list.length === 3)
          ) {
            return el;
          } else if (index === 0 && list.length < 3 && list.length > 1) {
            return el + " " + dictionary.and + " ";
          } else {
            return el + ", ";
          }
        }
        return null;
      });
    }
  };

  const changeAudiosaladStatus = (relId, status, type) => {
    Release.updateAudiosaladStatus(relId._id, status).then((res) => {
      if (!res.error && res.message) {
        getReleases();
        for (let i = 0; i <= list.length; i++) {
          if (list[i]?._id === relId._id) {
            list[i].step = type === 1 ? "pendingDistribution" : "readyToSent";
          }
        }
        setNewList(list);
        let succesRel =
          type === 1
            ? dictionary.releaseSendToDistribution.replace(
                "--name--",
                relId.title
              )
            : type === 2
            ? dictionary.releaseResendToReview.replace("--name--", relId.title)
            : dictionary.releaseRevokeDistribution.replace(
                "--name--",
                relId.title
              );
        notification.success({
          message: succesRel,
          duration: 6,
          placement: "bottomRight",
        });
      } else {
        notification.warning({
          message: dictionary.stripeError,
          duration: 6,
          placement: "bottomRight",
        });
      }
    });
  };

  const getActionsReview = (rel) => {
    let stepActions = "";
    switch (rel.step) {
      case "readyToSent":
        stepActions = (
          <div className="action-btn">
            <Button
              size="ms"
              className="grn"
              onClick={() =>
                changeAudiosaladStatus(rel, "Pending Distribution", 1)
              }
              text={dictionary.sendForDistribution}
            ></Button>
            <Button
              size="ms"
              className="rd"
              onClick={() => showRejectNoteModal(rel)}
              text={dictionary.rejectSendToClient}
            ></Button>
          </div>
        );
        break;
      case "inReview":
        stepActions = (
          <div className="action-review">{dictionary.sendingToStores}</div>
        );
        break;
      case "pendingDistribution":
        stepActions = distro ? (
          <div className="action-btn">
            <Button
              size="ms"
              className="grn"
              onClick={() => sendInReview(rel)}
              text={dictionary.sendToAudiosalad}
            ></Button>
            <Button
              size="ms"
              className="rd"
              onClick={() => changeAudiosaladStatus(rel, "Ready to Sent", 2)}
              text={dictionary.resendToReview}
            ></Button>
          </div>
        ) : (
          <div className="action-btn">
            <Button
              size="ms"
              className="rd"
              onClick={() => changeAudiosaladStatus(rel, "Ready to Sent", 3)}
              text={dictionary.revokeDistribution}
            ></Button>
          </div>
        );
        break;
      default:
        break;
    }
    return stepActions;
  };

  const items = [
    {
      label: <div className="item-priority none">{dictionary.none}</div>,
      key: "none",
    },
    {
      label: <div className="item-priority low">{dictionary.low}</div>,
      key: "low",
    },
    {
      label: <div className="item-priority med">{dictionary.medium}</div>,
      key: "medium",
    },
    {
      label: <div className="item-priority high">{dictionary.high}</div>,
      key: "high",
    },
  ];

  const handlePriority = (priority, relId) => {
    Admin.changePriority(relId, priority).then((res) => {
      if (res.status === 200) {
        // getReleases()
        for (let i = 0; i <= list.length; i++) {
          if (list[i]?._id === relId) {
            list[i].priority = priority;
          }
        }
        setNewList(list);
        getList();
      } else {
        notification.warning({
          message: dictionary.stripeError,
          duration: 6,
          placement: "bottomRight",
        });
      }
    });
  };

  const getPriority = (relId, data) => {
    let type = data ? data : "none";
    return (
      <Dropdown
        menu={{
          items,
          selectable: true,
          defaultSelectedKeys: [type],
          onClick: (e) => handlePriority(e.key, relId),
        }}
        trigger={["click"]}
        placement="bottomRight"
        overlayClassName="drop-priorities"
      >
        <button className={"priority-btn " + type}>
          {type ? dictionary[type] : dictionary.none}
        </button>
      </Dropdown>
    );
  };

  const getList = async () => {
    let listArray = [];
    const imgSrcBucket =
      // eslint-disable-next-line no-undef
      process.env.REACT_APP_S3_BUCKET_CDN_URL || process.env.REACT_APP_STORAGE;
    list.map((el, index) => {
      let image = el.image
        ? el.image
        : el.image_cover
        ? // eslint-disable-next-line no-undef
          imgSrcBucket + el._id + "/" + el.image_cover.filename
        : null;
      let imageResized = null;
      if (image) {
        let checksum = el.image_cover?.checksum || "";
        const getResized = image.replace(
          /.jpg|.png|.jpeg|.heic/gi,
          function () {
            return "50x50.webp?v=" + checksum;
          }
        );
        imageResized = getResized;
      }
      let name = el.name ? el.name : el.title ? el.title : dictionary.noTitle;
      let title = !el.name
        ? el.title
          ? el.title
          : dictionary.noTitle
        : el.name;
      let titleImg = name.substring(0, 4);
      let created = el.createdAt
        ? moment.utc(el.createdAt).startOf("day").format("YYYY/MM/DD")
        : null;
      let updated = el.updatedAt
        ? moment.utc(el.updatedAt).startOf("day").format("YYYY/MM/DD")
        : null;
      let featured = el.is_featured ? dictionary.yes : dictionary.no;
      let labelName =
        typeTable === 1
          ? el.label
            ? el.label.name
            : ""
          : el.email
          ? el.email
          : "-";
      let formats = el.type ? el.type : "-";
      let steps =
        el.step && statusTag ? (
          <div
            className={
              " tag-step " + el.step + (type !== "admin" ? "client" : "")
            }
          >
            {el.step === "readyToSent" && type !== "admin"
              ? dictionary.inReview
              : dictionary[el.step]}
          </div>
        ) : el.step ? (
          dictionary[el.step]
        ) : null;
      let reqBy = el.label ? el.label.name : "-";
      let dsps = el.dsp_release ? getDspList(el.dsp_objects) : "";
      let actions = el.step ? getActionsReview(el) : "";
      let dateRelease = el.date
        ? moment.utc(el.date).startOf("day").format("YYYY/MM/DD")
        : null;
      let priority =
        el.step !== "inReview" ? getPriority(el._id, el.priority) : "-";
      if (
        !allReleasesAdmin &&
        el.step &&
        (el.step === "draft" ||
          el.step === "rejected" ||
          el.step === "published" ||
          (distro && el.step === "readyToSent"))
      )
        return null;

      return listArray.push({
        index: index,
        key: el._id || index,
        details: (
          <div key={el._id} className="avatar-admin">
            {image ? (
              <img
                src={imageResized}
                alt={titleImg}
                id={"imgAdm" + el._id}
                onError={(e) => previewImg(e, el._id, image)}
              />
            ) : (
              <Avatar name={name || dictionary.noTitle} size="50" round="5px" />
            )}
          </div>
        ),
        detail: (
          <div className="center-details">
            {isAdminRels ? (
              <Link to={"/release/view?r=" + el._id + "&section=" + section}>
                <EyeOutlined className="view-details-rel" />
              </Link>
            ) : null}

            {canUpdate && !isAdminRels && !isUserRel ? (
              <EditOutlined
                onClick={() => showModal(el)}
                className="edit-detail"
              />
            ) : null}

            {isUserRel && (el.step === "draft" || el.step === "rejected") ? (
              <Link ref={redirect} to={route + el._id}>
                <EditOutlined
                  onClick={() => showModal(el)}
                  className="edit-detail"
                />
              </Link>
            ) : isUserRel ? (
              <Link ref={redirect} to={route + el._id}>
                <EyeOutlined className="view-details-rel" />
              </Link>
            ) : isAdminRels ? (
              <Link
                to={
                  "/release/view?r=" +
                  el._id +
                  "&edit=true" +
                  "&section=" +
                  section
                }
              >
                <EditOutlined className="view-edit-rel" />
              </Link>
            ) : null}

            {el.step === "draft" && isUserRel ? (
              <DeleteOutlined
                className="delete-detail"
                onClick={() => showDeleteDraft(el._id, title)}
              />
            ) : null}
            {canDelete && !isAdminRels && !isUserRel ? (
              <DeleteOutlined
                onClick={() => remove(index, el)}
                className="delete-detail"
              />
            ) : null}
          </div>
        ),
        requestedBy: <div className="req-by">{reqBy}</div>,
        storesRequested: <div className="list-dsp">{dsps}</div>,
        releaseDate: dateRelease,
        actions: actions,
        priority: priority,
        name: title,
        title: <div className="title-rel">{title}</div>,
        desc: labelName,
        featured: featured,
        createdAt: created,
        estatus: steps,
        format: formats,
        updated: updated,
      });
    });
    setTableData(listArray);
  };

  const searchInAdmin = (value) => {
    let filteredNames = [...originalTable];
    switch (filter) {
      case 0:
        typeTable === 1
          ? (filteredNames = originalTable.filter(
              (el) =>
                el.name.toLowerCase().includes(value.toLowerCase()) ||
                el.label.name.toLowerCase().includes(value.toLowerCase()) ||
                (el.is_featured
                  ? dictionary.yes.includes(toTitleCase(value))
                  : dictionary.no.includes(toTitleCase(value))) ||
                moment(el.createdAt).format("YYYY/MM/DD").includes(value) ||
                moment(el.updatedAt).format("YYYY/MM/DD").includes(value)
            ))
          : typeTable === 2
          ? (filteredNames = originalTable.filter(
              (el) =>
                (el.title
                  ? el.title.toLowerCase().includes(value.toLowerCase())
                  : dictionary.noTitle.includes(value.toUpperCase())) ||
                el.type.toLowerCase().includes(value.toLowerCase()) ||
                dictionary[el.step]
                  .toLowerCase()
                  .includes(value.toLowerCase()) ||
                moment(el.createdAt).format("YYYY/MM/DD").includes(value)
            ))
          : (filteredNames = originalTable.filter(
              (el) =>
                el.name.toLowerCase().includes(value.toLowerCase()) ||
                el.email.toLowerCase().includes(value.toLowerCase()) ||
                moment(el.createdAt).format("YYYY/MM/DD").includes(value) ||
                moment(el.updatedAt).format("YYYY/MM/DD").includes(value)
            ));
        break;
      case "Title":
        filteredNames = originalTable.filter((el) =>
          el.title !== null
            ? el.title.toLowerCase().includes(value.toLowerCase())
            : dictionary.noTitle.includes(value.toUpperCase())
        );
        break;
      case "Name":
        filteredNames = originalTable.filter((el) =>
          el.name.toLowerCase().includes(value.toLowerCase())
        );
        break;
      case "Email":
        filteredNames = originalTable.filter((el) =>
          el.email.toLowerCase().includes(value.toLowerCase())
        );
        break;
      case "Label":
        filteredNames = originalTable.filter((el) =>
          el.label.name.toLowerCase().includes(value.toLowerCase())
        );
        break;
      case "Featured":
        filteredNames = originalTable.filter((el) =>
          el.is_featured
            ? dictionary.yes.includes(toTitleCase(value))
            : dictionary.no.includes(toTitleCase(value))
        );
        break;
      case "Format":
        filteredNames = originalTable.filter((el) =>
          el.type.toLowerCase().includes(value.toLowerCase())
        );
        break;
      case "Status":
        filteredNames = originalTable.filter((el) =>
          dictionary[el.step].toLowerCase().includes(value.toLowerCase())
        );
        break;
      case "Created":
        filteredNames = originalTable.filter((el) =>
          moment(el.createdAt).format("YYYY/MM/DD").includes(value)
        );
        break;
      case "Updated":
        filteredNames = originalTable.filter((el) =>
          moment(el.updatedAt).format("YYYY/MM/DD").includes(value)
        );
        break;
      default:
        setNewList(originalTable);
        break;
    }
    setNewList(filteredNames);
  };

  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const getDisplayGenres = async (genres) => {
    let genreNames = "";
    await genres.forEach((el, i) => {
      if (el.length > 0) {
        if (i > 0) {
          genreNames += ", ";
        }
        genreNames += el.genre.name + " / " + el.subgenre.name;
      }
    });
    return genreNames;
  };

  const orderedList = (list = [], roles = false) => {
    const count = list.length;
    if (Array.isArray(list)) {
      return list.map((el, index) => {
        if (count === index + 1 && count > 1) {
          return (
            " " +
            dictionary.and +
            " " +
            (roles ? dictionary.artistType[el] : el.name)
          );
        } else if (count - 2 === index && count > 1) {
          return roles ? dictionary.artistType[el] : el.name;
        } else if (count < 3) {
          return (roles ? dictionary.artistType[el] : el.name) + " ";
        } else {
          return (roles ? dictionary.artistType[el] : el.name) + ", ";
        }
      });
    }
  };

  const artistsPreview = (artistsData) => {
    const onlyMain = artistsData.filter((el) => el.role.includes("main"));
    const onlyFeat = artistsData.filter((el) => el.role.includes("feature"));
    const countFeat = onlyFeat.length;

    let setMains = orderedList(onlyMain);

    let setFeat = onlyFeat.map((el, index) => {
      if (countFeat === index + 1 && countFeat > 1) {
        return " " + dictionary.and + " " + el.name;
      } else if (countFeat < 3) {
        return dictionary.featuring + el.name + " ";
      } else if (index === 0) {
        return dictionary.featuring + el.name + ", ";
      } else {
        return el.name + ", ";
      }
    });
    setMains.push(setFeat);
    return setMains;
  };

  const getDisplayTracks = async (tracks) => {
    return await Bluebird.map(tracks, async (el, i) => {
      const artistName = await artistsPreview(el.artists);
      const genres = await getDisplayGenres(el.genres);
      const publishers = await getDisplayPublishers(el.producers);
      const contributors = await getDisplayContributors(
        el.producers,
        el.writers,
        el.participants
      );
      return {
        key: i,
        title: el.title,
        version: el.version || "--",
        isrc: el.isrc,
        artist: artistName,
        genre: genres,
        publishers: (
          <React.Fragment>
            {publishers ? (
              <ReleaseElement
                title={dictionary.publishers}
                value={publishers}
                className="tracks-rel-admin"
              />
            ) : null}
            {contributors ? (
              <ReleaseElement
                title={dictionary.contributors}
                value={contributors}
                className="tracks-rel-admin"
              />
            ) : null}
          </React.Fragment>
        ),
      };
    });
  };

  const getDisplayPublishers = async (producers) => {
    let publisherNames = "";
    await producers.forEach((el, i) => {
      if (el.role === "publisher") {
        if (i > 0) {
          publisherNames += ", ";
        }
        publisherNames += el.name;
      }
    });
    return publisherNames;
  };

  const getDisplayContributors = async (producers, writers, participants) => {
    let contributorsNames = "";
    if (producers) {
      await producers.forEach((el, i) => {
        if (el.role !== "publisher") {
          if (i > 0) {
            contributorsNames += ", ";
          }
          contributorsNames += el.name + " (" + el.role + ")";
        }
      });
    }
    if (writers) {
      await writers.forEach((el) => {
        contributorsNames += ", " + el.name + " (" + el.role + ")";
      });
    }
    if (participants) {
      await participants.forEach((el) => {
        contributorsNames += ", " + el.name;
      });
    }
    return contributorsNames;
  };

  const onSearch = (value) => {
    getSearch(value.target.value);
    searchInAdmin(value.target.value);
  };

  const handleSelect = (e) => {
    getFilter(e);
  };

  const handleCancel = () => {
    //showModalRelease(false);
    setReleaseData({ _id: null });
  };

  const sendInReview = async (rel = null) => {
    setErrMsg(null);
    setLoadingList(true);
    const updateStatus = await Admin.updateStatus(
      rel ? rel._id : releaseData._id
    );
    let titleRel = rel ? rel.title : releaseData.title;
    if (updateStatus.status === 200) {
      setLoadingList(false);
      if (updateStatus.body.error === "releaseCheckData") {
        setErrMsg(dictionary.releaseCheckDataAdmin);
      } else {
        if (updateStatus.body.error === "releaseInReview") {
          setErrMsg(dictionary.releaseInReview);
        } else {
          getAllReleases();
          getReleases();
          handleCancel();
          let succesRel = dictionary.releaseSendToReview.replace(
            "--name--",
            titleRel
          );
          notification.success({
            message: succesRel,
            duration: 6,
            placement: "bottomRight",
          });
        }
      }
    } else {
      setLoadingList(false);
      setErrMsg(dictionary.stripeError);
    }
  };

  return (
    <div className="tables">
      <div className="top-actions-srch">
        <div className="searchers">
          <Search
            name="searcher"
            value={search}
            mobile="responsive"
            onSearch={onSearch}
            className="searcher sec back"
            enterButton={<SearchIcon />}
            placeholder={dictionary.search}
          />
          {filtersBar()}
        </div>
        {permission &&
        permission.release &&
        permission.release.add &&
        (title === "Releases" || title === "Lanzamientos") ? (
          <Link to="/newRelease" className="new-release">
            <Button
              size="fullWidth"
              className="new-rel-btn"
              text={dictionary.createNewRel}
            />
          </Link>
        ) : null}
      </div>
      <div className="general-card-admin">
        <div className="table-admin inline-contend">
          <div className="header-tab-ad"></div>
          <Spin spinning={loadList} className="spinner">
            <Table
              rowKey="index"
              columns={columns}
              pagination={true}
              dataSource={dataSource}
              className="content-table-admin"
            />
          </Spin>
        </div>
        {error ? <p className="text-error relative-text">{error}</p> : null}
      </div>
    </div>
  );
};

export default Tables;
