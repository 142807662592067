import React, { useContext, useState, useEffect } from "react";
import { notification } from "antd";
import { UserContext } from "../../context/User";
import { LanguageContext } from "../../context/Language";
import Admin from "../../classes/admin/admin";
import TableUsers from "../../components/Users/TableUsers/TableUsers.jsx";
import ModalStatus from "../../components/Users/ModalStatus/ModalStatus.jsx";
import { StopOutlined } from "@ant-design/icons";
import "./users.css";

const Users = () => {

  const { dictionary } = useContext(LanguageContext);
  const { allUsers, getAllUsers } = useContext(UserContext);
  const [loadList, setLoading] = useState(false);
  const [listUserAdmin, setUsersAdmin] = useState([]);
  const [search, getSearch] = useState(null);
  const [initialized, setIinitialized] = useState(false);
  const [statusData, setstatusData] = useState({
    userid: "",
    name: "",
    show: false,
    type: "",
  });

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line
  }, [allUsers]);

  const columns = [
    {
      title: dictionary.name,
      dataIndex: "name",
      className: "users-title-table",
    },
    {
      title: dictionary.email.split(" ")[0],
      dataIndex: "email",
      className: "title-category",
    },
    {
      title: dictionary.type,
      dataIndex: "type",
      className: "title-category",
    },
    {
      title: "plan",
      dataIndex: "plan",
      className: "title-category",
    },
    {
      title: dictionary.labels,
      dataIndex: "labels",
      className: "title-category album",
    },
    {
      title: dictionary.artists,
      dataIndex: "artists",
      className: "title-category album",
    },
    {
      title: dictionary.verified,
      dataIndex: "verified",
      className: "title-category",
    },
    {
      title: dictionary.createdAt,
      dataIndex: "createdAt",
      className: "title-category",
    },
    {
      title: dictionary.status,
      dataIndex: "status",
      className: "title-category",
    },
    {
      title: dictionary.details,
      dataIndex: "detail",
      className: "title-category",
    },
    {
      title: dictionary.completedPayee,
      dataIndex: "completedPayee",
      className: "title-category ps",
    },
  ];

  const getUsers = () => {
    setLoading(true);
    setUsersAdmin(allUsers);
    setLoading(false);
  };

  const showModalUpdateStatus = (user_id = "", user = null, type) => {
    setstatusData({
      name: user,
      userid: user_id,
      show: true,
      type: type,
    });
  }

  const hideModalStatus = () => {
    setstatusData({
      name: "",
      userid: "",
      show: false,
      type: "",
    });
  };

  const statusUser = (id ,type) => {
    setLoading(true);
    setUsersAdmin([])
    let estatus = type === "disable" ? "disabled" : "active";
    Admin.userStatus(id, estatus).then((res) => {
      if (res.status === 200) {
        getAllUsers();
        getUsers();
        if (estatus === "active") {
          notification.success({
            message: dictionary.activeUserSuccess,
            duration: 3,
            placement: "topRight",
          });
      } else {
        notification.success({
          message: dictionary.delUserSuccess,
          duration: 3,
          placement: "topRight",
          icon: <StopOutlined style={{ color: '#f28f8f' }} />,
        });
      }
      }
    }).catch(() => {
      notification.error({
        message: dictionary.errorGeneral,
        duration: 3,
        placement: "topRight",
      });
    }).finally(() => {
      setLoading(false);
      hideModalStatus();
      getUsers();
      getSearch(null);
      setIinitialized(false);
    });
  };

  return (
    <React.Fragment>
      <div className="container-admin">
        <TableUsers
          search={search} 
          getSearch={getSearch}
          initialized={initialized}
          setIinitialized={setIinitialized}
          isAdmin={true}
          error={false}
          remove={false}
          released={true}
          canDelete={true}
          loadList={loadList}
          isUser={true}
          subjects={listUserAdmin || []}
          list={listUserAdmin || []}
          setNewList={setUsersAdmin}
          title={dictionary.users}
          getUsers={getUsers}
          columns={columns}
          showModalUpdateStatus={showModalUpdateStatus}
        />
      </div>
      <ModalStatus
        statusData={statusData}
        hideModalStatus={hideModalStatus}
        statusUser={statusUser}
      />
    </React.Fragment>
  );
};

export default Users;
