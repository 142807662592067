import {
  Card,
  Col,
  Row,
  Button,
  Typography,
  Popconfirm,
  notification,
} from "antd";
import React, { useContext, useState } from "react";
import MarkIcon from "../../../Icon/MarkIcon";
import { LanguageContext } from "../../../../context/Language";
import AdminCalls from "../../../../classes/admin/admin";
import moment from "moment";

const Contract = ({
  isEditMode,
  membership,
  isLoading,
  userId,
  refreshUser,
}) => {
  const [enableEdit, setEnableEdit] = useState(false);
  const [updating, setUpdating] = useState(false);
  const { dictionary } = useContext(LanguageContext);

  const callSwitchUserMembership = async () => {
    setUpdating(true);
    const res = await AdminCalls.switchUserMembership(userId);
    if (!res?.error) {
      notification.success({
        placement: "bottomRight",
        message: dictionary.processSuccess,
        description: dictionary.errorTryAgain,
      });
    } else {
      notification.error({
        key: "releaseDetailNotification",
        placement: "bottomRight",
        message: dictionary.errorGeneral,
      });
    }
    refreshUser();
    setUpdating(false);
  };

  const drawButtonChangeMembership = () => {
    if (membership?.scheduled_to_change_at)
      return (
        <p className="mt-2">
          {dictionary.contractAlreadyScheduled}{" "}
          <b>
            {moment(membership?.scheduled_to_change_at).format("MMM DD HH:mm")}{" "}
            (UTC)
          </b>
        </p>
      );

    if (!enableEdit)
      return (
        <Button
          className="mt-2"
          disabled={isLoading || !enableEdit}
          type="primary"
          block
        >
          {dictionary.changeContractType}
        </Button>
      );

    return (
      <Popconfirm
        placement="topLeft"
        title={dictionary.contractConfirmChange}
        onConfirm={() => callSwitchUserMembership()}
        okText={dictionary.yes}
        cancelText="No"
      >
        <Button
          className="mt-2"
          disabled={isLoading || !enableEdit}
          type="primary"
          block
        >
          {dictionary.changeContractType}
        </Button>
      </Popconfirm>
    );
  };

  return (
    <div className="general_information user-detls">
      <Row>
        <Col>
          <Typography.Title level={3}>{dictionary.contract}</Typography.Title>
        </Col>
        <Col></Col>
      </Row>
      <Row justify="space-around" className="user-div">
        <Col xs={24} lg={24} className="info-one">
          <Card loading={isLoading | updating}>
            {isEditMode ? (
              <Button
                className={"edit_button usr" + (enableEdit ? " active" : "")}
                shape="circle"
                icon={<MarkIcon />}
                onClick={() => setEnableEdit(!enableEdit)}
              />
            ) : null}
            <Row justify="space-between" className="mt-2 mr-4 content-user">
              <Col className="label" xs={24} md={24} lg={12}>
                <label>{dictionary.membership}</label>
                <p className="mayus">{membership?.plan || ""}</p>
              </Col>
              <Col className="label" xs={24} md={24} lg={12}>
                <label>{dictionary.price}</label>
                <p>
                  {membership?.price === 0
                    ? dictionary.free
                    : membership?.price}
                </p>
              </Col>
              <Col className="label" xs={24} md={24} lg={12}>
                <label>{dictionary.renewalDate}</label>
                <p>{moment(membership?.renewal_date).format("YYYY/MMM/DD")}</p>
              </Col>
              <Col className="label" xs={24} md={24} lg={12}>
                <label>{dictionary.period}</label>
                <p>{dictionary[membership?.period]}</p>
              </Col>
              <Col className="label" xs={24} md={24} lg={12}>
                <label>{dictionary.type}</label>
                <p>
                  {membership?.type === "profit-share"
                    ? dictionary["royalty"]
                    : dictionary[membership?.type]}
                </p>
              </Col>
              <Col xs={24} md={24} lg={12}>
                {drawButtonChangeMembership()}
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Contract;
