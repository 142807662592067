import React, { useContext, useEffect, useState, useMemo } from "react";
import { Switch, Checkbox, Skeleton, Button } from "antd";
import { LanguageContext } from "../../../../context/Language";
import { AppContext } from "../../../../context/App";
import Bluebird from "bluebird";
import MarkIcon from "../../../../components/Icon/MarkIcon";
import "./territory.css";

const Copyright = ({
  isEditMode,
  territory = [],
  isLoading,
  dispatch
}) => {

  const CheckboxGroup = Checkbox.Group;
  const { dictionary } = useContext(LanguageContext);
  const { allCountries, allRegions, countriesOptions } = useContext(AppContext);

  const [checked, setCheckedSwitch] = useState(false);
  const [checkedList, setCheckedList] = useState(territory);
  const [disabled, setDisabled] = useState(true);
  const [disableCountry, setDisabledCountry] = useState(true);
  const [checkAllRegion, setCheckAllRegion] = useState(false);
  const [checkAllCountry, setCheckAllCountry] = useState(false);
  const [checkedListRegion, setCheckedListRegion] = useState([]);
  const [enableEdit, setEnableEdit] = useState(false);

  let getContinents = useMemo(() => allRegions.map((el) => el.value));

  useEffect(() => {
    if (territory) {
      const terr = territory;
      if (!terr.includes("WW") && terr.length !== 0) {
        setDisabled(false);
        setDisabledCountry(false);
        setCheckedSwitch(true);
        setCheckedList(terr);
        dispatch({
          type: "objectChange",
          payload: {
            name: "territory",
            original: territory,
            value: terr,
          },
        });
        let totalRegions = getRegionByCountry(allCountries, allRegions);
        let checkCountries = totalRegions.length === 6 ? true : false;
        let checkRegions = totalRegions.length === 6 ? true : false;
        setCheckedListRegion(
          checkRegions
            ? getContinents
            : totalRegions.length !== 0
            ? totalRegions
            : territory
        );
        setCheckAllRegion(checkRegions);
        setCheckAllCountry(checkCountries);
      }
    }
    // eslint-disable-next-line
  }, [territory]);

  const getRegionByCountry = (countries, regions) => {
    const getting = [];
    try {
      regions.forEach((elRegion) => {
        let filtered = countries.filter(
          (el) => territory.includes(el._id) && el.region === elRegion.value
        );
        let countOf = countries.filter(
          (e) => e.region === elRegion.value
        ).length;
        if (filtered.length !== 0 && countOf === filtered.length)
          getting.push(elRegion.value);
      });
      return getting;
    } catch (e) {
      return [];
    }
  };

  const onChangeSwitch = (checked) => {
    if (!disableCountry) {
      setDisabledCountry(true);
    }
    if (checked === false) {
      setCheckedList(["WW"]);
      setCheckedListRegion([]);
      setCheckAllRegion(false);
      setCheckAllCountry(false);
      dispatch({
        type: "objectChange",
        payload: {
          name: "territory",
          original: territory,
          value: ["WW"],
        },
      });
    }
    setDisabled(!checked);
    setCheckedSwitch(checked);
  };

  const onCheckAllRegionChange = (e) => {
    const newList = e.target.checked
      ? allRegions.map((region) => region.value)
      : [];
    setCheckedListRegion(newList);
    setCheckAllRegion(e.target.checked);
    if (e.target.checked === false) {
      setCheckedList(["WW"]);
      setCheckAllCountry(false);
      dispatch({
        type: "objectChange",
        payload: {
          name: "territory",
          original: territory,
          value: ["WW"],
        },
      });
    } else {
      const nextList = [allCountries.map((allCountries) => allCountries._id)][0];
      setCheckedList(nextList);
      dispatch({
        type: "objectChange",
        payload: {
          name: "territory",
          original: territory,
          value: nextList,
        },
      });
      setCheckAllCountry(true);
      setDisabledCountry(
        e.target.checked === true || newList.length > 0 ? false : true
      );
    }
  };

  const onChangeCheckRegion = async (list = []) => {
    setCheckedListRegion(list);
    if (list.length >= 1) {
      await getByRegion(list);
    } else {
      setCheckedList(["WW"]);
      dispatch({
        type: "objectChange",
        payload: {
          name: "territory",
          original: territory,
          value: ["WW"],
        },
      });
    }
    setCheckAllRegion(list.length === allRegions.length);
    setDisabledCountry(list.length > 0 ? false : true);
  };

  const getByRegion = async (region) => {
    let countries = [];
    await Bluebird.map(region, async (reg) => {
      const found = await allCountries.filter((el) => el.region === reg);
      countries = [...countries, ...found];
      const nextList = [countries.map((countries) => countries._id)][0];
      setCheckedList(nextList);
      dispatch({
        type: "objectChange",
        payload: {
          name: "territory",
          original: territory,
          value: nextList,
        },
      });
    });
    setCheckAllCountry(region.length === 6 ? true : false);
    return [countries.map((countries) => countries._id)][0];
  };

  const onAllCountryCheckboxChange = (e) => {
    const newList = e.target.checked
      ? [allCountries.map((allCountries) => allCountries._id)][0]
      : ["WW"];

    const newListRegions = e.target.checked
      ? allRegions.map((region) => region.value)
      : [];
    setCheckedList(newList);
    dispatch({
      type: "objectChange",
      payload: {
        name: "territory",
        original: territory,
        value: newList,
      },
    });
    setCheckAllRegion(e.target.checked);
    setCheckedListRegion(newListRegions);
    setCheckAllCountry(e.target.checked);
    if (e.target.checked === false) {
      setCheckedListRegion([]);
      setCheckAllRegion(false);
    }
  };

  const onCountryCheckboxChange = (list = []) => {
    setCheckedList(list);
    dispatch({
      type: "objectChange",
      payload: {
        name: "territory",
        original: territory,
        value: list,
      },
    });
    setCheckAllCountry(list.length === allCountries.length);
    if (list.length === 0) {
      setCheckedListRegion([]);
    }
  };

  return (
    <div className="region-box">
      <div className="title">
        <h1>{dictionary.territories}</h1>
        {isEditMode ? (
          <Button
            className={"edit_button" + (enableEdit ? " active" : "")}
            shape="circle"
            icon={<MarkIcon />}
            onClick={() => setEnableEdit(!enableEdit)}
          />
        ) : null}
      </div>
      {isEditMode ? (
        <div className="description">
          <Switch
            className="general-switch"
            onChange={onChangeSwitch}
            checked={checked}
            disabled={!enableEdit}
          />
          {dictionary.territoriesEdit}
        </div>
      ) : null}
      <div className="territories-box">
        <div className="check-div left-check">
          <Checkbox
            indeterminate={false}
            onChange={onCheckAllRegionChange}
            checked={checkAllRegion}
            className="check-all"
            disabled={!enableEdit || disabled}
          >
            {dictionary.regions}
          </Checkbox>
          {isLoading ? (
            <Skeleton
              active
              size={10}
              title={{ width: 150 }}
              paragraph={{ width: Array(6).fill(150), rows: 6 }}
            />
          ) : (
            <CheckboxGroup
              value={checkedListRegion}
              onChange={onChangeCheckRegion}
              className="check-country"
              options={allRegions}
              disabled={!enableEdit || disabled}
            />
          )}
        </div>
        <div className="check-div right-check">
          <Checkbox
            indeterminate={false}
            onChange={onAllCountryCheckboxChange}
            checked={checkAllCountry}
            className="check-all"
            disabled={!enableEdit || disableCountry}
          >
            {dictionary.countries}
          </Checkbox>
          {isLoading ? (
            <Skeleton
              active
              size={10}
              title={{ width: 150 }}
              paragraph={{ width: Array(6).fill(150), rows: 6 }}
            />
          ) : (
            <CheckboxGroup
              value={checkedList}
              onChange={onCountryCheckboxChange}
              className="check-country"
              options={countriesOptions}
              disabled={!enableEdit || disableCountry}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Copyright;
