import React, { useContext, useState, useEffect, useRef } from "react";
import { Row, Col, Upload, Checkbox } from "antd";
import Input from "../../../../../Form/Input";
import Radio from "../../../../../Form/Radio";
import Select from "../../../../../Form/Select";
import TrashIcon from "../../../../../Icon/TrashIcon";
import Language from "../../../../../../classes/language/language.jsx";
import { LanguageContext } from "../../../../../../context/Language";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import GenreSubGenreSelect from "../../../../../Form/GenreSubGenreSelect";

const TrackInfoFirst = ({
  state,
  nameRef,
  audioRef,
  isrcRef,
  infoTrack,
  errorsTrack,
  changeHandler,
  handleSelect,
  handleChangeAudioUpload,
  loading,
  audioUrl,
  openDelete,
  deleteSongModal,
  audio,
  permissionCheckboxChangeHandler,
}) => {
  const audioPreviewRef = useRef();

  const { dictionary } = useContext(LanguageContext);

  const [dataLanguages, setLanguages] = useState([]);
  const [fileType, setFileType] = useState("audio/x-wav");

  const exampleRadio = [
    { value: "original", name: dictionary.original },
    { value: "cover", name: dictionary.cover },
    { value: "remix", name: dictionary.remix },
  ];

  useEffect(() => {
    getLanguages();
    // eslint-disable-next-line
  }, []);

  // Add the correct source type to audio preview node
  useEffect(() => {
    const audioTag = audioPreviewRef.current;
    if (audioTag) {
      while (audioTag.firstChild) audioTag.removeChild(audioTag.firstChild);

      const source = document.createElement("source");
      source.setAttribute("type", fileType);
      source.setAttribute("src", audio);
      audioTag.append(source);
    }
  }, [audio, fileType]);

  const getLanguages = async () => {
    try {
      const languages = await Language.languages();
      setLanguages(languages.body.languages);
    } catch (e) {
      setLanguages([]);
    }
  };

  const triggerUpdateAudioUpload = (e) => {
    setFileType(e?.file?.type || "audio/x-wav");
    handleChangeAudioUpload(e);
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8, color: "#8c8c8c" }}>
        {dictionary.upload}
      </div>
    </div>
  );

  // Called each render, i cant make it better :(
  const getPermission = (type) => {
    const permission =
      infoTrack?.permission?.filter((item) => item.type === type) || [];
    return permission.length && permission[0].enabled;
  };

  return (
    <React.Fragment>
      <Row className="gutter-row">
        <Col xs={24} sm={24} md={12} lg={12} className="col1">
          <Input
            refs={nameRef}
            name="title"
            required={true}
            value={infoTrack?.title || ""}
            onChange={(e) => changeHandler(e, "title")}
            label={dictionary.trackName}
            error={errorsTrack.trackNameErr}
            className={errorsTrack.trackNameErr ? "inputErr " : ""}
          />
          <Select
            refs={audioRef}
            required={true}
            showSearch={true}
            name="audio_lang"
            options={dataLanguages}
            error={errorsTrack.audioLangErr}
            value={infoTrack?.audio_lang || state.lang}
            placeholder={dictionary.select}
            label={dictionary.audioLanguage}
            onChange={(e) => handleSelect(e, "audio_lang")}
            className={(errorsTrack.audioLangErr ? "countErr " : "") + " selector-general"}
          />
          {!state.isNew ? (
            <Input
              refs={isrcRef}
              required={true}
              name="isrc"
              value={infoTrack?.isrc || ""}
              onChange={(e) => changeHandler(e, "isrc")}
              label={dictionary.isrc}
              error={errorsTrack.isrcErr}
              className={errorsTrack.isrcErr ? "inputErr " : ""}
            />
          ) : null}
          <label className="allWidth">{dictionary.trackPermission}</label>
          <Checkbox
            name={"track_sale"}
            checked={getPermission("track_sale")}
            className="check-terms"
            onChange={permissionCheckboxChangeHandler}
          >
            {dictionary.sale}
          </Checkbox>
          <Checkbox
            name={"stream"}
            checked={getPermission("stream")}
            className="check-terms"
            onChange={permissionCheckboxChangeHandler}
          >
            {dictionary.stream}
          </Checkbox>
          <Checkbox
            name={"download"}
            checked={getPermission("download")}
            className="check-terms"
            onChange={permissionCheckboxChangeHandler}
          >
            {dictionary.download}
          </Checkbox>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} className="col2">
          <Input
            name="version"
            onChange={(e) => changeHandler(e, "version")}
            value={infoTrack?.version || ""}
            label={dictionary.titleVersion}
            error={errorsTrack.trackVerErr}
            className={errorsTrack.trackVerErr ? "inputErr " : ""}
          />
          <GenreSubGenreSelect
            title={dictionary.genre}
            name="audio_genre"
            required={true}
            value={infoTrack?.subgenres || []}
            error={errorsTrack.genreTrackErr || ""}
            onChange={(e) => handleSelect(e, "subgenres")}
          />
          <Radio
            required={true}
            options={exampleRadio}
            onChange={(e) => changeHandler(e, "type")}
            value={infoTrack?.type || "original"}
            className="radio-options"
            label={dictionary.trackType}
          />
        </Col>
      </Row>
      <div className="audio">
        <h2 style={{ width: "100%" }}>{dictionary.audio}*</h2>
        {audioUrl || infoTrack?.cover ? (
          <TrashIcon onClick={openDelete} />
        ) : null}
        {deleteSongModal()}
      </div>
      {audio ? (
        <audio ref={audioPreviewRef} controls="nodownload" className="audio-preview">
          <source src={audio} />
          Your browser does not support the <code>audio</code> element.
        </audio>
      ) : (
        <div>
          <Upload
            customRequest={() => {}}
            name="avatar"
            accept="audio/x-wav, audio/wav, audio/x-flac, audio/flac"
            showUploadList={false}
            onChange={triggerUpdateAudioUpload}
            listType="picture-card"
            className={(errorsTrack.fileErr ? "imageErr " : "") + " avatar-uploader"}
            maxCount={1}
          >
            {uploadButton}
          </Upload>
          <div className="cover-desc">{dictionary.audioDesc}</div>
          {errorsTrack.fileErr ? (
            <div className="upload-text-error">{errorsTrack.fileErr}</div>
          ) : null}
        </div>
      )}
    </React.Fragment>
  );
};

export default TrackInfoFirst;
